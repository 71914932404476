import "../../Styles/Galery.scss";
import React from "react";


import original_1 from "../../img/Italy/Gallery_1.jpeg";
import original_2 from "../../img/Italy/Gallery_2.jpeg";
import original_3 from "../../img/Italy/Gallery_3.jpeg";
import original_4 from "../../img/Italy/Gallery_4.jpeg";
import original_5 from "../../img/Italy/Gallery_5.jpeg";
import original_6 from "../../img/Italy/Gallery_6.jpeg";
import original_7 from "../../img/Italy/Gallery_7.jpeg";
import original_8 from "../../img/Italy/Gallery_8.jpeg";
import original_9 from "../../img/Italy/Gallery_9.jpeg";
import original_10 from "../../img/Italy/Gallery_10.jpeg";
import original_11 from "../../img/Italy/Gallery_11.jpeg";
import original_12 from "../../img/Italy/Gallery_12.jpeg";
import original_13 from "../../img/Italy/Gallery_13.jpeg";
import original_14 from "../../img/Italy/Gallery_14.jpeg";
import original_15 from "../../img/Italy/Gallery_15.jpeg";
import original_16 from "../../img/Italy/Gallery_16.jpeg";
import original_17 from "../../img/Italy/Gallery_17.jpeg";
import original_18 from "../../img/Italy/Gallery_18.jpeg";
import original_19 from "../../img/Italy/Gallery_19.jpeg";

import ImageGallery from "react-image-gallery";
const images = [
    {
        original: original_1,
    },
    {
        original: original_2,
    },
    {
        original: original_3,
    },
    {
        original: original_4,
    },
    {
        original: original_5,
    },
    {
        original: original_6,
    },
    {
        original: original_7,
    },
    {
        original: original_8,
    },
    {
        original: original_9,
    },
    {
        original: original_10,
    },
    {
        original: original_11,
    },
    {
        original: original_12,
    },
    {
        original: original_13,
    },
    {
        original: original_14,
    },

    {
        original: original_15,
    },
    {
        original: original_16,
    },
    {
        original: original_17,
    },
    {
        original: original_18,
    },
    {
        original: original_19,
    },
];

function ItalyGallery() {
    return (
        <div className="pageContainer space">
            <div className="gallery-container">
                <div className="image-gallery-wrapper pictures">
                    <ImageGallery
                        items={images}
                        disableThumbnailScroll={true}
                        showThumbnails={false}
                        showBullets={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default ItalyGallery;
