import React, { useState } from "react";
import styles from "./../../Styles/VideoStudy.module.scss";
import VideoBox from "../../Components/VideoBox";

const videoList_1 = [
  {
    id: 1,
    title: "The History of Switzerland",
    duration: "11:39 mins",
    sourceLink: "https://www.youtube.com/embed/rikPE3FXd3I?si=iZyi2yhiymxv96ru"
  },
  {
    id: 2,
    title: "How was Switzerland Formed? – The History of Switzerland",
    duration: "11:02 mins",
    sourceLink: "https://www.youtube.com/embed/oMmchavfwwI?si=FU6Ppit8T7IJU4dH"
  },
  {
    id: 3,
    title: "The Economy of Switzerland, Unraveling Swiss Economy",
    duration: "13:03 mins",
    sourceLink: "https://www.youtube.com/embed/De6rFH8oKsI?si=DRUbylueX9zR5SKB"
  },
  {
    id: 4,
    title: "Murren, Lauterbrunnen, Switzerland – Incredible Beautiful Swiss Alpine Village",
    duration: "24:26 mins",
    sourceLink: "https://www.youtube.com/embed/scYOqD3l_OA?si=_dyEGy1l-WMbdSZq"
  },
  {
    id: 5,
    title: "Appenzell: Traditional Switzerland – Rick Steves",
    duration: "5:10 mins",
    sourceLink: "https://www.youtube.com/embed/MnnKHu11GMc?si=yQ94cgQQG3XUgcbQ"
  },
  {
    id: 6,
    title: "Ebenalp, Switzerland – Unbelievable Places on Earth",
    duration: "30:06 mins",
    sourceLink: "https://www.youtube.com/embed/xg8bppyVL-0?si=sUDSv5VAmnWWnqlz"
  },
  {
    id: 7,
    title: "Zurich, Switzerland",
    duration: "10:03 mins",
    sourceLink: "https://www.youtube.com/embed/jxkMIiqakBo?si=wns7cLj56IoNZNiK"
  },
  {
    id: 8,
    title: "Don’t Miss Lucerne when in Switzerland",
    duration: "7:33 mins",
    sourceLink: "https://www.youtube.com/embed/JbNe-7lqgVQ?si=cMXC4W8VD_jyWgDk"
  },
  {
    id: 9,
    title: "10 Reasons Why Switzerland the Best Country in the World?",
    duration: "10:13 mins",
    sourceLink: "https://www.youtube.com/embed/iASc9-mc7kE?si=pResMOCGHpzy6q4d"
  },

]
const videoList_2 = [

  {
    id: 1,
    title: "Who was Ulrich Zwingli?",
    duration: "8:31 mins",
    sourceLink: "https://www.youtube.com/embed/483TY96uciw?si=2NBtQ0RokaCLC1tI"
  },
  {
    id: 2,
    title: "The Reformers: Zwingli (In this presentation, the speaker is speaking as if he is Ulrich Zwingli, giving his own life story).",
    duration: "24:36 mins",
    sourceLink: "https://www.youtube.com/embed/sa-uM-EBREc?si=XSz21Z3AchRCSmVk"
  },
  {
    id: 3,
    title: "Reformation Overview / Ulrich Zwingli and John Calvin (Episode 4)",
    duration: "28:02 mins",
    sourceLink: "https://www.youtube.com/embed/hQx3ZUsMoO8?si=wZzVpGCPFNcEaZw7"
  },
  {
    id: 4,
    title: "Zwingli and the Radical Reformation (I don’t agree with everything this speaker says about the Anabaptists, but for the most part, this is an interesting message and I think it would make for a good discussion when we are together on our trip).",
    duration: "53:14 mins",
    sourceLink: "https://www.youtube.com/embed/rH0yM8zVKX8?si=kOsjmi4NuKl2Bqmd"
  }

]
const videoList_3 = [

  {
    id: 3,
    title: "Spread of the Anabaptists: The Amish, Mennonites, and Hutterites",
    duration: "15:49 mins",
    sourceLink: "https://www.youtube.com/embed/6B6z7Kcpnsc?si=hodYRhQEXn5_s6N9"
  },
  {
    id: 4,
    title: "Michael Sattler – A story of faith",
    duration: "5:41 mins",
    sourceLink: "https://www.youtube.com/embed/BA85cRFzSGY?si=M2M6pjjSyKicfvWh"
  },
  {
    id: 5,
    title: "Unbelievably True Anabaptist Stories: Dirk Willems and Michael Sattler",
    duration: "7:32 mins",
    sourceLink: "https://www.youtube.com/embed/as-BSFSZWdk?si=FUb9kIyQpYGhNLrf"
  },
  {
    id: 6,
    title: "Courageous Faith: The Swiss Mennonite and Amish Story ",
    duration: "54:48 mins",
    sourceLink: "https://www.youtube.com/embed/VDpfSrUAZnY?si=nJlshD8RzEQ5Ywep"
  }

]

function VideoStudy() {

  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  //const [videoLoading, setVideoLoading] = useState(true);

  const closeModal = () => {
    setModal(!modal);
  };


  return (
    <div className="pageContainer">
      <div className="title">Documentaries for the Switzerland Tour
      </div>
      <p className="text">You are invited/encouraged to watch these documentaries in the months prior to departure. It will add greatly to your experience/learning. I do not endorse everything said in these documentaries, but for the most part, I do.
      </p>
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Historical and cultural background</div>
      {
        videoList_1.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Ulrich Zwingli and the Swiss Reformation</div>
      {
        videoList_2.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />

      ) : null}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>The Anabaptists and the Radical Reformation</div>
      {
        videoList_3.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />

      ) : null}

    </div>

  );
};

export default VideoStudy;