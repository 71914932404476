import picture from "../img/Guide.jpg";
import styles from "./AboutGuid.module.scss";

function AboutGuide() {
  return (
    <div className={styles.about}>
      <div className={styles.container}>
        <h1 className="title">Our tour leader</h1>
        <div>
          <div className={styles.photo}>
            <img src={picture} alt="tour guide" />
          </div>
          <p>
            Dan has served as a college professor for over 25 years, teaching
            Old and New Testament history, and has traveled overseas more than
            50 times, leading many adults and college students to inspiring
            places. After making several personal trips to Israel, Dan realized
            the profound influence a biblical and historical tour can have in
            the life of a Christian, and with the urging and support of his
            wife, Martha, and friends, he founded Tribe of Dan Tours (2014) to
            make trips affordable, enjoyable, and faith-building. He has led
            hundreds of people overseas on tours to Israel, Turkey, and Greece.
            He views his tours as pilgrimages that are intended to deepen one’s
            walk with God and enrich one’s knowledge of the Bible.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutGuide;
