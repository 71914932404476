import scripture from "./../../img/Turkey/Scripture_Reading_for_Turkey_Trip.pdf";
import styles from "../../Styles/ScriptureGuide.module.scss";


function Scriptureguide() {

  return (
    <div className="pageContainer">
      <div className="title">Scripture Reading</div>
      <p className="secondaryTitle">Turkey Tour</p>
      <div className="printBox">
        <div className="print">
          <a href={scripture} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <p className="text">Reading through the book of Revelation before the trip is a very good idea.
      </p>
      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 1, Sunday - Sept 22
        </p>
        <p className="text margin-left">Depart USA in the PM
        </p>
      </div>
      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 2, Monday - Sept 23

        </p>
        <p className="text margin-left">Arrive in Istanbul and get to the hotel

        </p>
      </div>
      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 3, Tuesday - Sept 24
        </p>
        <p className="text margin-left">Tour Istanbul: Hagios Sofia, cruise on the Bosphorus, and more
        </p>
      </div>
      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 4, Wednesday - Sept 25
        </p>
        <p className="text margin-left">Place: Smyrna Agora and new Museum
        </p>
        <p className={`text margin-left ${styles.city}`}>Read Revelation 2:8-11</p>
      </div>
      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 5, Thursday - Sept 26
        </p>
        <p className="text margin-left">Place: Ephesus
        </p>
        <p className={`text margin-left ${styles.city}`}>Read Revelation 2:1-7, Acts 19:1-41 (You could read Paul’s letter to the Ephesians)
        </p>
      </div>

      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 6, Friday - Sept 27
        </p>
        <p className="text margin-left">Place: day boat trip to the Island of Patmos: Cave of the Apocalypse
        </p>
        <p className={`text margin-left ${styles.city}`}>Read Revelation 1:1-20
        </p>
      </div>

      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 7, Saturday - Sept 28

        </p>
        <p className="text margin-left">Place: Ephesus
        </p>
        <p className={`text margin-left ${styles.city}`}>Read Revelation 2:1-7, Acts 19:1-41 (You could read Paul’s letter to the Ephesians)
        </p>
      </div>

      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 8, Sunday - Sept 29
        </p>
        <p className="text margin-left">Place: Pergamum Acropolis & Asklepion; Thyatira
        </p>
        <p className={`text margin-left ${styles.city}`}>Read Revelation 2:12-29
        </p>
      </div>

      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 9, Monday - Sept 30
        </p>
        <p className="text margin-left">Place: Sardis, Philadelphia, Hierapolis
        </p>
        <p className={`text margin-left ${styles.city}`}>Read: Revelation 3:1-13
        </p>
      </div>

      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 10, Tuesday - Oct 1
        </p>
        <p className="text margin-left">Place: Laodicea and Colossae
        </p>
        <p className={`text margin-left ${styles.city}`}>Read: Revelation 3:14-22, Colossians 4:7-18 (you could read Paul’s letter to the Colossians)
        </p>
      </div>

      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 11, Wednesday - Oct 2
        </p>
        <p className="text margin-left">Place: Antalya and Perga
        </p>
        <p className={`text margin-left ${styles.city}`}>Read: Acts 13:13-14, Acts 14:24-26
        </p>
      </div>

      <div className="margin-bottom">
        <p className={styles.paragraphTitle}>Day 12, Thursday - Oct 3</p>

        <p className="text margin-left">Fly to Istanbul in the AM and to the USA in the PM
        </p>
      </div>

    </div>
  );
}

export default Scriptureguide;
