import styles from "../../Styles/Prayer.module.scss";
import pictureOne from "../../img/Turkey/Prayer_1_Theater_of_Ephesus.jpg";
import pictureTwo from "../../img/Turkey/Prayer_2_The_Bosphorus.jpeg";
import pictureThree from "../../img/Turkey/Prayer_3_Turkish_Countryside.jpeg";
import prayerPdf from "../../img/Turkey/Prayer_Turkey_tour.pdf";

function TurkeyPrayer() {
  return (
    <div className="pageContainer">
      <p className="title">Prayer for our tour of Turkey</p>
      <div className="printBox">
        <div className="print">
          <a href={prayerPdf} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      <p className="secondaryTitle">
        I invite you to pray this (or something like it) every day for the 30
        days before departure.
      </p>

      <p className="text">
        Thank you, God, for the privilege of visiting the land where the Apostle John, the Apostle Paul, and other early Christians walked, suffered persecution, and planted churches. May this trip be a time where we develop a much greater insight into the truths of Scripture.
        Let our pre-trip studies prove to be enlightening and adequate preparation for the on-field experience

      </p>
      <p className="text">
        We ask that our trip will be a powerful spiritual experience for
        everyone. May we never be quite the same after this pilgrimage. Let it
        be a catalyst for transformation wherever that is needed in our
        individual lives. As we go from site to site, may the spiritual presence
        of Jesus Christ be powerfully perceived.
      </p>
      <p className="text">
        We seek your divine protection for all life and property. Let nothing be
        lost or hurt. Save us from illness and any digestive distress from new
        or different foods. Let there be no medical emergencies for anyone in
        the group. We pray for smooth flights and transportation that operate
        without mechanical problems. Deliver us from accidents while traveling.
      </p>
      <p className="text">
        We know how important it is to get proper rest at night, so we pray that
        our accommodations will be pleasant and sufficient. May they be quiet
        and restful, so we are able to get the sleep we need to keep up our
        strength for the busy days. May we adjust quickly to a new time zone. Work on our behalf so that the trip has the feel that God is leading and
        providing. We pray that the schedule will come together just right and
        that we will not feel a spirit of hurry and pressure.
      </p>
      <p className="text">
        Give our group unity and camaraderie. May each member of the team look after and care
        for one another. Let us be thoughtful and considerate. If the trip
        presents any challenges, let us face them with flexibility, courage and
        faith, not with doubt and complaints. Banish fear from all minds and
        hearts.
      </p>
      <p className="text">
        We pray, Lord, that you will give our group leader special strength.
        Empower him with health and strength. Let him always be under your
        influence so that he will make the right decisions for the trip. May
        your Spirit anoint him in guiding and teaching
      </p>
      <p className="text">
        Lord, let us all be a good testimony for You wherever we are and with
        whomever we meet. As your ambassadors, let the light of Christ be so
        radiant in us that all will know we are God&apos;s people. Help us to be
        sensitive to the cultural differences we find in Turkey. Lead us to the
        right words and actions for each context in which we find ourselves.
        Given a chance, let us be a witness for You.
      </p>
      <p className="text">
        To You, the Alpha and Omega, we pray that our trip may be blessed in
        every way from the beginning to the end. We ask this in the name of
        Jesus Christ our Lord, AMEN!
      </p>
      <div className={styles.pictureBox}>
        <div>
          <img
            className={styles.picture}
            src={pictureOne}
            alt="Israel, people praying"
          />
        </div>
        <div className={styles.hide}>
          <img
            className={styles.picture}
            src={pictureTwo}
            alt="Israel, people praying"
          />
        </div>
        <div>
          <img
            className={styles.picture}
            src={pictureThree}
            alt="Israel, people praying"
          />
        </div>
      </div>
    </div>
  );
}

export default TurkeyPrayer;
