import { useEffect, useState } from "react";
import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";
import { useLocation } from 'react-router-dom'

function Homepage({ trip }) {
  // let location = useLocation();
  // const [dates, setDates] = useState("");
  // console.log(location.state.title);
  // //let dates = "";
  // if (location.state.title === "Greece-april") {
  //   setDates("April 22 - May 3, 2024");
  // } else {
  //   setDates("September 5-12, 2024");
  // }
  console.log(trip);


  return (
    <div className={styles.backgroundPicture}>
      <div className={styles.titleBox}>
        <h1>Go to the enchanting land of</h1>
        <p className={styles.title}>Greece</p>
        <p className={styles.dates}>September 5 - 16, 2024</p>
        <div className={styles.description}>
          <p>Walk in the footsteps of St. Paul.</p>
          <p>Visit great historical and cultural sites all over this land.</p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;

// //<div>
//         <img
//           className={styles.homePhoto}
//           src={GreeceTrip}
//           alt="Greece monastery"
//         />
//       </div>
