import IsraelMenu from "./IsraelMenu";
import Footer from "../../Components/Footer";
import { Outlet } from "react-router-dom";

function Trip() {
  return (
    <div>
      <IsraelMenu />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Trip;
