
import styles from "./PictureBox.module.scss";

function PictureBox(props) {
    return (
        <div className={styles.pictureBox}>
            <div>
                <img
                    className={styles.picture}
                    src={props.pictureOne}
                />
            </div>
            <div className={styles.hide}>
                <img
                    className={styles.picture}
                    src={props.pictureTwo}
                />
            </div>
            <div>
                <img
                    className={styles.picture}
                    src={props.pictureThree}
                />
            </div>
        </div>
    );

}
export default PictureBox;

