import React, { useState } from "react";
import styles from "./../../Styles/VideoStudy.module.scss";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import VideoBox from "../../Components/VideoBox";


const videoList_1 = [
  {
    id: 1,
    title: "101 Facts about Poland",
    duration: "33:29 mins",
    sourceLink: "https://www.youtube.com/embed/dNEHSXzZAQI?si=1O6CDGZ2JKvCc_la"
  },
  {
    id: 2,
    title: "Discover Poland: Most Religious & Best Educated Country in Europe? | 53 Surprising Facts",
    duration: "16:44 mins",
    sourceLink: "https://www.youtube.com/embed/88j-Zdcn19U?si=j2V22HfE7CJqUEmp"
  },
  {
    id: 3,
    title: "Amazing Places to visit in Poland / Best Place to Visit in Poland – Travel Video",
    duration: "10:49 mins",
    sourceLink: "https://www.youtube.com/embed/-88MfJr4-dg?si=a6rlGaAQu0Ac3IGi"
  },
  {
    id: 4,
    title: "Poland Rediscovered: Kraków, Auschwitz, and Warsaw – Rick Steves",
    duration: "26:08 mins",
    sourceLink: "https://www.youtube.com/embed/blg6CY4iYXI?si=M4z3GHf7rMFwcg3d"
  }

]
const videoList_2 = [
  {
    id: 1,
    title: "The Warsaw Uprising – The Unstoppable Spirit of the Polish Resistance",
    duration: "10:52 mins",
    sourceLink: "https://www.youtube.com/embed/9BjIYKq4CVk?si=-ZPsritI0KtEPdtY"
  },
  {
    id: 2,
    title: "Warsaw Uprising, 1944 / A Study of Urban Combat",
    duration: "1:00:46 mins",
    sourceLink: "https://www.youtube.com/embed/Qm20ENn9MAE?si=MTBgK7gr5AkZMvAk"
  }

]
const videoList_3 = [
  {
    id: 1,
    title: "Warsaw Ghetto Uprising",
    duration: "10:01 mins",
    sourceLink: "https://www.youtube.com/embed/8bjY6L_MUkY?si=IU502zY9San0DGwd"
  },
  {
    id: 2,
    title: "Everyday Life in the Ghettos / Yad Vashem",
    duration: "8:36 mins",
    sourceLink: "https://www.youtube.com/embed/p4VqngTaiPg?si=RVRR_eMmXYNucNOp"
  },
  {
    id: 3,
    title: "There was no hope. Warsaw Ghetto Uprising 19th April 1943",
    duration: "29:40 mins",
    sourceLink: "https://www.youtube.com/embed/VOpKGTz4GJA?si=30RyUtpsTUbUc46M"
  },
  {
    id: 4,
    title: "Warsaw Ghettograd – The 1943 Uprising (Episode 1)",
    duration: "17:02 mins",
    sourceLink: "https://www.youtube.com/embed/NILGN8Zu4YY?si=qKU99vyNGtHO3861"
  },
  {
    id: 5,
    title: "Warsaw Ghettograd – The 1943 Uprising (Episode 2)",
    duration: "14:55 mins",
    sourceLink: "https://www.youtube.com/embed/DyCuW-wChrw?si=kMzhzSxNXypphWCU"
  },
  {
    id: 6,
    title: "Warsaw Ghettograd – The 1943 Uprising (Episode 3)",
    duration: "11:28 mins",
    sourceLink: "https://www.youtube.com/embed/15kettj4BxU?si=6tqMlRqFomPs6nUG"
  }
]
const videoList_4 = [
  {
    id: 1,
    title: "A City that Survived its own Death",
    duration: "3:35 mins",
    sourceLink: "https://www.youtube.com/embed/qXOdcmLWPsw?si=iev3Ly58L9iHbYwq"
  },
  {
    id: 2,
    title: "The most destroyed city in world history - Warsaw in Ruins 1944",
    duration: "2:36 mins",
    sourceLink: "https://www.youtube.com/embed/yy1kOwUyEsM?si=zSUDkrjarXZF2WrY"
  },
  {
    id: 3,
    title: "Rebuilding Warsaw: From the Ashes of WWII",
    duration: "19:32 mins",
    sourceLink: "https://www.youtube.com/embed/u5KJ4eXV59w?si=nBbtc0uQOoYwxV4H"
  },
  {
    id: 4,
    title: "Restoring Warsaw's Architectural Splendor: The Remarkable Rebuilding Process after WWII",
    duration: "9:16 mins",
    sourceLink: "https://www.youtube.com/embed/q8LGr6xwG4A?si=RITEvDoNDRsV78zY"
  }
]
const videoList_5 = [
  {
    id: 1,
    title: "Genocide of Jews in Poland | The Abyss Ep. 8 | Full Documentary",
    duration: "51:28 mins",
    sourceLink: "https://www.youtube.com/embed/dqB9rYufPXI?si=5oLqdYyi7zukGzQ-"
  },
  {
    id: 2,
    title: "The Suffering of the prisoners in Auschwitz",
    duration: "8:40 mins",
    sourceLink: "https://www.youtube.com/embed/nW_Nvomoyis?si=K1MrLJOGgx0eK3Qt"
  },
  {
    id: 3,
    title: "Auschwitz One Day | Special | Full Documentary",
    duration: "51:36 mins",
    sourceLink: "https://www.youtube.com/embed/5vIZ0kOpWvw?si=De0fK2qLcy4snVgJ"
  },
  {
    id: 4,
    title: "A Day in the Worst Nazi Concentration Camp / Auschwitz-Birkenau",
    duration: "9:52 mins",
    sourceLink: "https://www.youtube.com/embed/RjktTmNIqYE?si=R6O82s8ic9F2Cwd4"
  },
  {
    id: 5,
    title: "The Vengeful Execution of the Commandant of Auschwitz – Rudolf Hoss",
    duration: "9:31 mins",
    sourceLink: "https://www.youtube.com/embed/SpuMo2eTqCM?si=7l-tsZe8VIuYbZYe"
  }
]

const videoList_6 = [
  {
    id: 1,
    title: "Oskar Schindler & The Story of Schindler’s Documentary",
    duration: "1:04:06 mins",
    sourceLink: "https://www.youtube.com/embed/wEztt2bfQok?si=bQzHUGJufwG86sew"
  },
  {
    id: 2,
    title: "Oskar Schindler's Factory & The Krakow Ghetto | History Traveler Episode 210",
    duration: "28:22 mins",
    sourceLink: "https://www.youtube.com/embed/IHZFVLbHx5E?si=W3QRYpCX0U6LMJ7V"
  }

]
const videoList_7 = [
  {
    id: 1,
    title: "The Wieliczka Salt Mine: 10 Fascinating Things to Know",
    duration: "7:21 mins",
    sourceLink: "https://www.youtube.com/embed/V-C0UA1Ej_s?si=CuJr9_QlBxh9wP1q"
  },
  {
    id: 2,
    title: "The Underground Kingdom: Exploring the Wieliczka Salt Mine",
    duration: "4:30 mins",
    sourceLink: "https://www.youtube.com/embed/Ieq2OdDz-Ss?si=nZeoVO_6IaOEEZ7D"
  },
  {
    id: 3,
    title: "Wieliczka Salt Mine is a must visit – Krakow Underground Salt Mines",
    duration: "6:23 mins",
    sourceLink: "https://www.youtube.com/embed/istcY-s0fws?si=uovkZ-G7q6ELDUj8"
  }

]

function VideoStudy() {

  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  //const [videoLoading, setVideoLoading] = useState(true);

  const closeModal = () => {
    setModal(!modal);
  };


  return (
    <div className="pageContainer">
      <div className="title">Documentaries for the Poland Tour
      </div>
      <p className="text">You are invited/encouraged to watch these documentaries in the months prior to departure. It will add greatly to your experience/learning.
      </p>
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Introduction to Poland
      </div>
      {
        videoList_1.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>The Warsaw Uprising
      </div>
      {
        videoList_2.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Warsaw Jewish Ghetto and Uprising</div>
      {
        videoList_3.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>The Rebuilding of Warsaw</div>
      {
        videoList_4.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Auschwitz / Birkenau</div>
      {
        videoList_5.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>The Oskar Schindler Story and the Krakow Ghetto</div>
      {
        videoList_6.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>The Wieliczka Salt Mine</div>
      {
        videoList_7.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />

      ) : null}
      <p className="text">About 10 hours</p>

    </div>

  );
};

export default VideoStudy;



{/* <section className={styles.modal__bg}>
          <div className={styles.modal__align}>
            <IoCloseOutline
              className={styles.modal__close}
              arial-label="Close modal"
              onClick={openModal}
            />
            <iframe
              //className="modal__video-style"
              loading="lazy"
              width="100%"
              height="100%"
              src={videoList_1[1].sourceLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen />

          </div>
        </section> */}
