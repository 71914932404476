import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPictureItaly}>
      <div className={styles.titleBox}>
        <h1>Visit the beautiful country of</h1>
        <p className={styles.title}>Italy</p>
        <p className={styles.dates}>April 2 - 11, 2024</p>
        <div className={styles.description}>
          <p>with all of its history, culture, and charm: </p>
          <p>Rome, Pompeii, Sorrento, the Amalfi Coast, and the island of Capri.</p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;