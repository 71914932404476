import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPictureEngland}>
      <div className={styles.titleBox}>
        <h1 className={styles.intro}>Visit this country of outstanding natural and historic sites.</h1>
        <p className={styles.title}>England</p>
        <p className={styles.dates}>October 7 - 17, 2024</p>
        <div className={styles.description}>
          <p>A trip that will include London, Epworth, Oxford, Bath, Bristol, and Wales.  </p>
          <p>Visit sites that were significant to John Wesley, C.S.Lewis, George Mueller, and Evan Roberts and the Welsh Revival of 1904-05.</p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;