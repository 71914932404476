import "../../Styles/Galery.scss";
import React from "react";


import original_1 from "../../img/Turkey/Gallery_1_Hadrian_s_Gate_Antalya.jpeg";
import original_2 from "../../img/Turkey/Gallery_2_Church_on_Patmos.jpg";
import original_3 from "../../img/Turkey/Gallery_3_Dr. Mark_Wilson&wife.jpeg";
import original_4 from "../../img/Turkey/Gallery_4_Ephesus.jpg";
import original_5 from "../../img/Turkey/Gallery_5_Island_Patmos.jpg";
import original_6 from "../../img/Turkey/Gallery_6_Istanbul.jpg";
import original_7 from "../../img/Turkey/Gallery_7_Laodicea.jpg";
import original_8 from "../../img/Turkey/Gallery_8_Latrine_Ephesus.jpg";
import original_9 from "../../img/Turkey/Gallery_9_Library_Celsus.jpg";
import original_10 from "../../img/Turkey/Gallery_10_Mountains_Turkey.jpg";
import original_11 from "../../img/Turkey/Gallery_11_Pamukkale _cotton_castle.jpg";
import original_12 from "../../img/Turkey/Gallery_12_Tel_Colossae.jpg";
import original_14 from "../../img/Turkey/Gallery_14_Port_of_Antalya.jpeg";
import original_15 from "../../img/Turkey/Gallery_15_Sardis.jpg";
import original_16 from "../../img/Turkey/Gallery_16_Store_in_Turkey.jpg";
import original_17 from "../../img/Turkey/Gallery_17_The_Bosphorus.jpeg";
import original_18 from "../../img/Turkey/Gallery_18_Turkish_Food.jpg";
import original_19 from "../../img/Turkey/Gallery_19_Theater_of_Ephesus.jpg";
import original_20 from "../../img/Turkey/Gallery_20_Top_of_Pergamos.jpg";

import ImageGallery from "react-image-gallery";
const images = [
    {
        original: original_1,
    },
    {
        original: original_2,
    },
    {
        original: original_3,
    },
    {
        original: original_4,
    },
    {
        original: original_5,
    },
    {
        original: original_6,
    },
    {
        original: original_7,
    },
    {
        original: original_8,
    },
    {
        original: original_9,
    },
    {
        original: original_10,
    },
    {
        original: original_11,
    },
    {
        original: original_12,
    },
    {
        original: original_14,
    },

    {
        original: original_15,
    },
    {
        original: original_16,
    },
    {
        original: original_17,
    },
    {
        original: original_18,
    },
    {
        original: original_19,
    },
    {
        original: original_20,
    },
];

function ItalyGallery() {
    return (
        <div className="pageContainer space">
            <div className="gallery-container">
                <div className="image-gallery-wrapper pictures">
                    <ImageGallery
                        items={images}
                        disableThumbnailScroll={true}
                        showThumbnails={false}
                        showBullets={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default ItalyGallery;
