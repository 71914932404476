import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
// import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
// import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
// import picture_4 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
// import picture_5 from "../../img/Greece/Day 5 Thessalonika.jpeg";
// import picture_6 from "../../img/Greece/Day 6 Kavala.jpeg";
// import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
// import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
// import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
// import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
// import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itinerary from "../../img/Poland/PolandItinerary.pdf";
import { IoClose } from "react-icons/io5";
import pictureOne from "./../../img/Poland/Itimerary_1.jpeg";
import pictureTwo from "./../../img/Poland/Itinerary_2.jpeg";
import pictureThree from "./../../img/Poland/Itinerary_3.jpeg";

function PolandItinerary() {
  //For day 2

  // const [boxState, setBoxState] = useState([
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  // ]);
  // const open = function (i) {
  //   const copy = [...boxState];
  //   copy[i] = true;
  //   setBoxState(copy);
  // };
  // const close = function (i) {
  //   const copy = [...boxState];
  //   copy[i] = false;
  //   setBoxState(copy);
  // };

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">May 6 - 17</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <p className="text">This trip will have a strong emphasis on WWII, the Holocaust, and the strength and resiliency of the Polish people. We will be staying in Warsaw, Krakow, and Zakopane. In Warsaw, we will tour with a local guide through the old city center and what was once the Jewish Ghetto. After taking a bus trip to Krakow, we will tour this charming cultural city/center that was once the home of Polish kings. We will visit Wawel Hill and the Old City, which has the largest square in all of Europe. We will tour Oscar Schindler’s factory (now a museum), where he saved over 1,200 Jews during WWII. A somber day trip will be spent at the Auschwitz/Birkenau concentration and extermination camp. Near Krakow, we will visit the Wieleczka Salt Mine, where, hundreds of feet below the surface of the ground, we will see churches and cathedrals carved out of salt. This is a phenomenal place that most people have never even heard of. Lastly, we will drive to the charming town of Zakopane in the southern mountains (Tatras) of Poland. This place is the Gatlinburg of Poland and the area is called “Little Switzerland." Here, we will spend a couple of relaxing days. We will ride a cable car that will transport us to the top of the mountains right on the border with Slovakia. This will be an exhilarating experience. Trust me! This is the longest cable car ride I have ever taken (some 15-20 minutes). The Polish people are very friendly, and the food is delicious and quite reasonably priced. If you are interested in Poland, this just might be the trip for you.</p>
      <div className="pictureRow">
        <div>
          <img
            className="picture"
            src={pictureOne}
            alt="Poland town"
          />
        </div>
        <div className="hide">
          <img
            className="picture"
            src={pictureTwo}
            alt="Poland solt mines"
          />
        </div>
        <div>
          <img
            className="picture"
            src={pictureThree}
            alt="Polamd mountains"
          />
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1</h2>
        <p className="text">
          Depart USA for Warsaw, Poland

        </p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2</h2>
        <p className="text">Arrive to Warsaw and get settled into our hotel (overnight in Warsaw)


          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 2*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div> */}
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3</h2>
        <p className="text">
          Tour the historical center of Warsaw with a local guide, visiting both the Old Town and the Warsaw Ghetto. During the Warsaw Uprising in August 1944, more than 85% of Warsaw's historic center was destroyed by Nazi troops. After the war, a five-year reconstruction campaign by its citizens resulted in today's meticulous restoration of the Old Town, with its churches, palaces, and marketplace. The Warsaw Ghetto was the largest of the Nazi ghettos during World War II and the Holocaust.  It was established in November 1940 by the German authorities. At its height, as many as 460,000 Jews were imprisoned there, in an area of 1.3 sq mi, with an average of 9.2 persons per room, barely subsisting on meager food rations. From the Warsaw Ghetto, Jews were deported to Nazi concentration camps and mass-killing centers. In the summer of 1942, at least 254,000 ghetto residents were sent to the Treblinka extermination camp.
          (overnight in Warsaw)


          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button> */}
        </p>
        {/*Box 3*/}
        {/* <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Athens: we will stroll through the Ancient Agora (Marketplace)
              visiting the Stoa of Attalos, a stoa lined with shops built in the
              2nd century B.C. which has since been reconstructed for use as the
              Museum of the Ancient Agora. Here we visit many other ancient
              ruins/buildings of ancient Athens. This is where philosophers like
              Socrates gave advice to “know thyself,” and Zeno the Stoic
              lectured on how to control one&apos;s emotions. This is also
              considered to be the seat of western democracy.
            </p>
            
          </div>
        </div> */}
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4</h2>
        <p className="text">
          Ride a bus from Warsaw to Krakow (4.5 hrs). In the evening, we will stroll through the Main Market Square, the biggest Medieval Plaza in Europe. Its 40,000 square meters are truly astonishing. It was designed in 1257, is considered Kraków's city center and has an important historical, cultural, and social significance. The entire medieval old town is among the first sites chosen for UNESCO’s original World Heritage List.
          (overnight in Krakow)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 4*/}
      {/* <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            On this day, we will drive to base of the highest mountain in
            Greece, Mt. Olympus, making stops at Marathon, and Thermopylae
            (scene of the famous 300 Spartans).
          </p>
          <p className="text">
            Marathon is a small town which was the site of the Battle of
            Marathon (490 BCE), in which the heavily outnumbered Athenian army
            defeated the Persians. It’s believed that Pheidippides, a Greek
            herald at the battle, was sent running from Marathon to Athens to
            announce the victory, which is how the marathon running race was
            conceived in modern times. But there is a lot more to this story and
            you will learn it.
          </p>
          
        </div>
      </div> */}
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5</h2>
        <p className="text">
          In the morning tour Wawel Hill. In 1978 Wawel was declared the first World Heritage Site as part of the historic center of Kraków. For centuries the residence of the kings of Poland and the symbol of Polish statehood, Wawel Castle is now one of the country's premier art museums. In the afternoon a Oscar Schindler’s factory tour. The Oscar Schindler’s Enamel Factory is where 1,200 Jews were employed by Schindler and whose lives were subsequently saved from the death camps. This factory is now a museum. (overnight in Krakow)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 5*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Thessalonica"
          />
          <p className="text">
            Travel to Thessalonica: Thessaloniki (pop. 814,000) is called the
            second capital by the Greeks, since it is not only the second
            largest city of Greece after Athens, but also the capital of
            Northern Greece and Macedonia. It is beautifully situated by the
            sea.
          </p>
          <p className="text">
            We will visit the ruins of the ancient city where Paul founded a
            church, stroll along the harbour, take a boat ride in the
            harbor/gulf (Aegean Sea), and view the statute of Alexander the
            Great
          </p>
        </div>
      </div> */}
      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6</h2>
        <p className="text">
          Tour Auschwitz/Birkenau, the largest of the Nazi concentration and extermination camps, which was operated by Nazi Germany in occupied Poland during World War II and the Holocaust. It consisted of Auschwitz I, the main camp in Oswiecim; Auschwitz II-Birkenau, a concentration and extermination camp with gas chambers. The camps became a major site of the Nazis' Final Solution to the Jewish question. 1.1 million people, of which 1 million were Jews, were murdered here between 1941-1945.
          (overnight in Krakow)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 6*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            Leaving Thessalonika we will travel east to Philippi: After settling
            into our lodgings, we will travel eight miles to the coast and eat
            at the harbour in Kavala (biblical Neapolis), the one Paul sailed
            into from Troas in Turkey/Asia Minor after he received the
            “Macedonian vision” (Acts 16:6-12).
          </p>
        </div>
      </div> */}
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7</h2>
        <p className="text">
          Visit the Wieliczka Salt Mine which is in the town of Wieliczka, near Krakow. The mine was excavated from the 13th century and produced table salt continuously until 1996 as one of the world's oldest operating salt mines. It reaches a depth of 1,000 feet and extends via horizontal passages and chambers for over 178 miles. It is now a World Heritage site, and its attractions include the shafts and labyrinthine passageways, displays of historic salt-mining technology, an underground lake, four chapels and numerous statues carved by miners out of the rock salt, and more recent sculptures by contemporary artists. It draws 1.2 million people annually. (overnight in Krakow)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 7*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            Tour the archaeological site of ancient Philippi where Paul and
            Silas were imprisoned for casting a spirit of divination out of a
            young slave girl and where they baptized their first European
            convert, Lydia, by the stream beside the town. We will have a Bible
            study from Philippians on the top of the acropolis of Philippi where
            we will enjoy a gorgeous view of the Macedonian mountains and plain
            below the acropolis where the great Roman civil war battle took
            place (42 BC). Here the forces of Octavius (Caesar Augustus) and
            Mark Anthony defeated the legions loyal to Brutus and Cassius, Roman
            senators, who were instigators in the murder of Julius Caesar two
            years earlier. This battle changed the ancient world forever as Rome
            was transformed into empire from a republic.
          </p>
        </div>
      </div> */}
      {/*Day 8*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8</h2>
        <p className="text">
          Mid-morning, we will pick up our rental cars and drive the 2.5 – 3 hr drive to the charming and popular town of Zakopane, which is in southern Poland at the base of the beautiful Tatra Mountains. On the drive, we will stop at a Polish restaurant that serves delicious traditional food. In the evening we will walk through the town center. This place is the Gatlinburg of Poland. (overnight in Zakopane)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 8*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            This will be a travel day to from Philippi to Meteora in the
            mountains of central Greece with a stop in Berea for lunch. It is
            believed that Paul was in the area where we will visit an ancient
            synagogue.
          </p>
          
        </div>
      </div> */}
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 9</h2>
        <p className="text">
          Some of this day’s adventure will be going to the nearby Tatra National Park and taking the long cable car ride to the top of the Tatra Mountains, which puts us right on the Slovakian border. From there, you can walk/hike across the border. (overnight in Zakopane)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(9);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 9*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[9] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(9);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_9}
            alt="Meteora Monastery"
          />
          <p className="text">
            Meteora: Our visit here will take in at least two of the stunning
            monasteries built on the cliffs of central Greece. There are six
            Meteora monasteries perched on the huge cliffs people can visit
            today. Serene, mystical, extraordinary, breathtaking, immense,
            inspiring, impressive. These are only some of the words people very
            often use in an effort to describe the Meteora monasteries.
          </p>
        </div>
      </div> */}
      {/*Day 10*/}
      <div>
        <h2 className={styles.dayNumber}>Day 10</h2>
        <p className="text">
          This will be a day of rest and relaxation. Several different optional activities will be available, from driving into the Slovakian mountains to hiking on various trails nearby. It will be a free day to do what each person wants. (overnight in Zakopane)


          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 10*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            This day we will drive to Corinth with a stop and lunch at the
            strait of Actium where Octavian gained his celebrated victory over
            the forces Mark Antony and Cleopatra in 31 BC. This naval battle
            resulted in Octavias (Augustus Caesar) being the sole emperor of the
            Roman Empire. This same man would later give the degree that moved
            Joseph and Mary to Bethlehem where Jesus Christ was born fulfilling
            the prophecy of Micah the prophet. We will also visit the camp of
            Octavian, and where he later set up a memorial to himself for
            winning that historic battle
          </p>
        </div>
      </div> */}
      {/*Day 11*/}
      <div>
        <h2 className={styles.dayNumber}>Day 11</h2>
        <p className="text">
          A five-hour drive to Warsaw will take us back to the place where we will fly back to the United States the following day. We will make several stops along the way for coffee and meals. (overnight in Warsaw)


          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 11*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            This day we will drive to Corinth with a stop and lunch at the
            strait of Actium where Octavian gained his celebrated victory over
            the forces Mark Antony and Cleopatra in 31 BC. This naval battle
            resulted in Octavias (Augustus Caesar) being the sole emperor of the
            Roman Empire. This same man would later give the degree that moved
            Joseph and Mary to Bethlehem where Jesus Christ was born fulfilling
            the prophecy of Micah the prophet. We will also visit the camp of
            Octavian, and where he later set up a memorial to himself for
            winning that historic battle
          </p>
        </div>
      </div> */}
      {/*Day 12*/}
      <div>
        <h2 className={styles.dayNumber}>Day 12</h2>
        <p className="text">
          Fly back to the USA
        </p>
      </div>




    </div>
  );
}

export default PolandItinerary;
