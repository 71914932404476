import "../../Styles/Galery.scss";
import React from "react";


import original_1 from "../../img/Israel/Israel_gallery_1.jpg";
import original_2 from "../../img/Israel/Israel_gallery_2.jpg";
import original_3 from "../../img/Israel/Israel_gallery_3.jpg";
import original_4 from "../../img/Israel/Israel_gallery_4.jpg";
import original_5 from "../../img/Israel/Israel_gallery_5.jpg";
import original_6 from "../../img/Israel/Israel_gallery_6.jpg";
import original_7 from "../../img/Israel/Israel_gallery_7.jpg";
import original_8 from "../../img/Israel/Israel_gallery_8.jpg";
import original_9 from "../../img/Israel/Israel_gallery_9.jpg";
import original_10 from "../../img/Israel/Israel_gallery_10.jpg";
import original_11 from "../../img/Israel/Israel_gallery_11.jpg";
import original_12 from "../../img/Israel/Israel_gallery_12.jpg";
import original_13 from "../../img/Israel/Israel_gallery_13.jpg";
import original_14 from "../../img/Israel/Israel_gallery_14.jpg";
import original_15 from "../../img/Israel/Israel_gallery_15.jpg";
import original_16 from "../../img/Israel/Israel_gallery_16.jpg";
import original_17 from "../../img/Israel/Israel_gallery_17.jpg";
import original_18 from "../../img/Israel/Israel_gallery_18.jpg";
import original_19 from "../../img/Petra/Petra_2.jpeg";

import ImageGallery from "react-image-gallery";
const images = [
    {
        original: original_1,
    },
    {
        original: original_2,
    },
    {
        original: original_3,
    },
    {
        original: original_4,
    },
    {
        original: original_5,
    },
    {
        original: original_6,
    },
    {
        original: original_7,
    },
    {
        original: original_8,
    },
    {
        original: original_9,
    },
    {
        original: original_10,
    },
    {
        original: original_11,
    },
    {
        original: original_12,
    },
    {
        original: original_13,
    },
    {
        original: original_14,
    },

    {
        original: original_15,
    },
    {
        original: original_16,
    },
    {
        original: original_17,
    },
    {
        original: original_18,
    },
    {
        original: original_19,
    },
];

function PetraIsraelGallery() {
    return (
        <div className="pageContainer space">
            <div className="gallery-container">
                <div className="image-gallery-wrapper pictures">
                    <ImageGallery
                        items={images}
                        disableThumbnailScroll={true}
                        showThumbnails={false}
                        showBullets={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default PetraIsraelGallery;
