// import React, { useState } from "react";
// import styles from "./../../Styles/VideoStudy.module.scss";
// import VideoBox from "../../Components/VideoBox";

// //Jhon Wesley
// const videoList_1 = [
//   {
//     id: 1,
//     title: "Ultimate Documentary On John Wesley with Insight from his life",
//     duration: "1 hr 3 mins",
//     sourceLink: "https://www.youtube.com/embed/Roj3US6pVSw?si=EwWobRDgJDGZWAl5"
//   },
//   {
//     id: 2,
//     title: "John Wesley’s New Room Meeting House",
//     duration: "2:21 mins",
//     sourceLink: "https://www.youtube.com/embed/YCZ9uP8ZzW0?si=HYXcUFMYmmSQW3Dx"
//   },
//   {
//     id: 3,
//     title: "New Room Chapel",
//     duration: "17:59 mins",
//     sourceLink: "https://www.youtube.com/embed/6wlzL2-o7vQ?si=Vt2_Ofu2MWxBiL-_"
//   },

// ]
// //George Meuller
// const videoList_2 = [
//   {
//     id: 1,
//     title: "GEORGE MULLER Documentary | A Cloud of Witnesses",
//     duration: "59:30 mins",
//     sourceLink: "https://www.youtube.com/embed/TQ8DKz1mpa8?si=sD6Ek2jv_YiQUKXK"
//   },
//   {
//     id: 2,
//     title: "31 Short Messages by George Mueller ",
//     duration: "2:06 mins",
//     sourceLink: "https://www.youtube.com/embed/JkK_nlKUWoo?si=7464gUVPgAeZsSUV"
//   },
//   {
//     id: 3,
//     title: "A message by John Piper about George Mueller",
//     duration: "1 hr 15 mins",
//     sourceLink: "https://www.youtube.com/embed/iyJXjs3uYSI?si=r_lILX17E0mJorhY"
//   },
//   {
//     id: 4,
//     title: "Autobiography of George Mueller (many parts)",
//     duration: "34:09 mins",
//     sourceLink: "https://www.youtube.com/embed/uAlLuYeLcZc?si=pZr9ObpxN-5YwFB9"
//   },

// ]

// //C.S.Lewis
// const videoList_3 = [
//   {
//     id: 1,
//     title: "The Life of C.S. Lewis by John Piper",
//     duration: "1 hr 15 mins",
//     sourceLink: "https://www.youtube.com/embed/DyPhY620e2Y?si=uGX_K7DGoiCcXLue"
//   },
//   {
//     id: 2,
//     title: "Beyond Narnia: The Real Life Of C.S. Lewis",
//     duration: "58:24 mins",
//     sourceLink: "https://www.youtube.com/embed/BAjwjs6uOso?si=tXnz30Oc15NiIynE"
//   },

//   {
//     id: 3,
//     title: "God is All You Need / C.S. Lewis",
//     duration: "9:53 mins",
//     sourceLink: "https://www.youtube.com/embed/21eHBhPcjV0?si=LEJHnTZN4vb6qBn-"
//   },
//   {
//     id: 4,
//     title: "C.S Lewis's surviving BBC radio address",
//     duration: "9 mins",
//     sourceLink: "https://www.youtube.com/embed/JHxs3gdtV8A?si=POTAPXusA3aYyppU"
//   },
//   {
//     id: 5,
//     title: "The Four Loves by C.S. Lewis Doodle",
//     duration: "30 mins",
//     sourceLink: "https://www.youtube.com/embed/3hM4izbColg?si=GM3V2r8YtRoet2qC"

//   },
//   {
//     id: 6,
//     title: "The Three Parts of Morality by C.S. Lewis Doodle",
//     duration: "14 mins",
//     sourceLink: "https://www.youtube.com/embed/MtTeCyrgjIQ?si=i8MPZNv17tFpmS-H"
//   },

// ]
// //Evan Roberts and Welsh Revival

// const videoList_4 = [
//   {
//     id: 1,
//     title: "1 of 2: EVAN ROBERTS & THE 1904/05 WELSH REVIVAL",
//     duration: "6 mins",
//     sourceLink: "https://www.youtube.com/embed/ACGbasEBjmM?si=i41eZuE4ob4htXSE"
//   },
//   {
//     id: 2,
//     title: "2 of 2: EVAN ROBERTS & THE 1904/05 WELSH REVIVAL",
//     duration: "7 mins",
//     sourceLink: "https://www.youtube.com/embed/wlaA08x3qzc?si=oRtQOqDt7VU7I656"
//   },
//   {
//     id: 3,
//     title: "The Welsh Revival - The 1904 Revival That Shook The Whole Earth",
//     duration: "1:04:24 mins",
//     sourceLink: "https://www.youtube.com/embed/iBmWNE3x6ro?si=sedsj_oOfDaYOXNm"
//   },
//   {
//     id: 4,
//     title: "The Awakening of 1904 In Wales by J. Edwin Orr",
//     duration: "29:18 mins",
//     sourceLink: "https://www.youtube.com/embed/yMcsNEHVGdU?si=mUlGDGujf3wDoyFy"
//   },

// ]
// //London
// const videoList_5 = [
//   {
//     id: 1,
//     title: "London: Historic and Dynamic: Rick Steves",
//     duration: "24:56 mins",
//     sourceLink: "https://www.youtube.com/embed/4p1rzw-7Jho?si=I68MRcttm5nK2QBp"
//   },
//   {
//     id: 2,
//     title: "Smithfield - The Infamous Site Of EXECUTION and BURNING ALIVE!",
//     duration: "6:19 mins",
//     sourceLink: "https://www.youtube.com/embed/rlw6g4-mVp8?si=FZIsiBF8ECfdFODz"
//   },

// ]
// //Oxford
// const videoList_6 = [
//   {
//     id: 1,
//     title: "Oxford: the full tour",
//     duration: "1 hr 12 mins",
//     sourceLink: "https://www.youtube.com/embed/M8EJ61gcqE8?si=g0FTytEBUUs-82UU"
//   },
//   {
//     id: 2,
//     title: "Oxford: city of dreaming sphires",
//     duration: "4:34 mins",
//     sourceLink: "https://www.youtube.com/embed/SzRH4LJPcRQ?si=Zml8Gr8eimW7Tze6"
//   },
// ]
// //The Cotswolds
// const videoList_7 = [
//   {
//     id: 1,
//     title: "Cotswolds, England: Village Charm",
//     duration: "3:43 mins",
//     sourceLink: "https://www.youtube.com/embed/kqOTwn313O8?si=DpSDrhOxW1hQMNPe"
//   },
//   {
//     id: 2,
//     title: "Cotswolds, England Castle Combe, Malmesbury, Bibury, Stow, Slaughter, Bourton, Tetbury",
//     duration: "7:45 mins",
//     sourceLink: "https://www.youtube.com/embed/yt6em3ZxOa4?si=nAxrByuVXkV3ECMd"
//   },
// ]
// //Bath
// const videoList_8 = [
//   {
//     id: 1,
//     title: "Walking Tour of Bath",
//     duration: "17:46 mins",
//     sourceLink: "https://www.youtube.com/embed/5i62kjj53_A?si=rss42yQVoTz4EyJA"
//   },
//   {
//     id: 2,
//     title: "Bath: complete walking tour",
//     duration: "13:40 mins",
//     sourceLink: "https://www.youtube.com/embed/fEHeB_gAt6E?si=m7uaT7ND189SGJxE"
//   },
//   // {
//   //   id: 3,
//   //   title: "Spread of the Anabaptists: The Amish, Mennonites, and Hutterites",
//   //   duration: "15:49 mins",
//   //   sourceLink: "https://www.youtube.com/embed/6B6z7Kcpnsc?si=hodYRhQEXn5_s6N9"
//   // },
// ]


// function VideoStudy() {

//   const [modal, setModal] = useState(false);
//   const [videoLink, setVideoLink] = useState("");
//   //const [videoLoading, setVideoLoading] = useState(true);

//   const closeModal = () => {
//     setModal(!modal);
//   };


//   return (
//     <div className="pageContainer">
//       <div className="title">England tour: books and documentaries
//       </div>
//       {/* <p className="text">You are invited/encouraged to watch these documentaries in the months prior to departure. It will add greatly to your experience/learning. I do not endorse everything said in these documentaries, but for the most part, I do.
//       </p> */}
//       <div className={`secondaryTitle ${styles.videoGroupTitle}`}>John Wesley
//       </div>
//       <h2 className={`secondaryTitle ${styles.subtitle}`}>Books:</h2>
//       <ul className={styles.bookList}>
//         <li className="text"><span>The Life of John Wesley: A Brand from the Burning</span> by Roy Hattersley</li>
//         <li className="text"><span>The Amazing John Wesley: An Unusual Look at an Uncommon Life</span>  by H. Newton Malony</li>
//         <li className="text"><span>John Wesley: His Life and Thought</span> by Timothy J. Crutcher</li>
//       </ul>
//       <h3 className={`secondaryTitle ${styles.subtitle}`}>Documentaries:</h3>

//       {
//         videoList_1.map((item, i) =>
//           <button onClick={() => {
//             setModal(!modal);
//             setVideoLink(item.sourceLink);
//           }} className={styles.videoTitle} key={i}>
//             {item.title}
//             <p>{item.duration}</p>
//           </button>
//         )}

//       <div className={`secondaryTitle ${styles.videoGroupTitle}`}>George Mueller
//       </div>
//       <h2 className={`secondaryTitle ${styles.subtitle}`}>Books:</h2>
//       <ul className={styles.bookList}>
//         <li className="text"><span>George Muller: Delighted in God</span> by Roger Steer</li>
//         <li className="text"><span>Autobiography of George Mueller</span>  by George Mueller</li>
//       </ul>
//       <h3 className={`secondaryTitle ${styles.subtitle}`}>Documentaries:</h3>
//       {
//         videoList_2.map((item, i) =>
//           <button onClick={() => {
//             setModal(!modal);
//             setVideoLink(item.sourceLink);
//           }} className={styles.videoTitle} key={i}>
//             {item.title}
//             <p>{item.duration}</p>
//           </button>
//         )}

//       {modal ? (
//         <VideoBox closeModal={closeModal} videoLink={videoLink} />

//       ) : null}

//       <div className={`secondaryTitle ${styles.videoGroupTitle}`}>C.S.Lewis </div>
//       <h2 className={`secondaryTitle ${styles.subtitle}`}>Books:</h2>
//       <ul className={styles.bookList}>
//         <li className="text"><span>C.S. Lewis: A Biography</span> by Roger Lanceyn Green and Walter Hooper</li>
//         <li className="text"><span>A Life Observed: A Spiritual Biography of C. S. Lewis</span> by Devin Brown and Douglas Gresham</li>
//       </ul>
//       <h3 className={`secondaryTitle ${styles.subtitle}`}>Documentaries:</h3>
//       {
//         videoList_3.map((item, i) =>
//           <button onClick={() => {
//             setModal(!modal);
//             setVideoLink(item.sourceLink);
//           }} className={styles.videoTitle} key={i}>
//             {item.title}
//             <p>{item.duration}</p>
//           </button>
//         )}

//       {modal ? (
//         <VideoBox closeModal={closeModal} videoLink={videoLink} />

//       ) : null}

//       <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Evan Roberts and the Welsh Revival </div>
//       <h2 className={`secondaryTitle ${styles.subtitle}`}>Books:</h2>
//       <ul className={styles.bookList}>
//         <li className="text"><span>The Life Story of Evan Roberts: and Stirring Experiences of the Welsh Revival</span>  by W Percy Hicks and Larry Martin</li>
//         <li className="text"><span>The Welsh Revival & The Story of the Welsh Revival: As Told by Eyewitnesses Together With a Sketch of Evan Roberts and His Message to The World</span> by William Stead, G.Campbell Morgan</li>
//       </ul>
//       <h3 className={`secondaryTitle ${styles.subtitle}`}>Documentaries:</h3>
//       {
//         videoList_4.map((item, i) =>
//           <button onClick={() => {
//             setModal(!modal);
//             setVideoLink(item.sourceLink);
//           }} className={styles.videoTitle} key={i}>
//             {item.title}
//             <p>{item.duration}</p>
//           </button>
//         )}

//       {modal ? (
//         <VideoBox closeModal={closeModal} videoLink={videoLink} />

//       ) : null}
//       <div className={`secondaryTitle ${styles.videoGroupTitle}`}>London </div>
//       {
//         videoList_5.map((item, i) =>
//           <button onClick={() => {
//             setModal(!modal);
//             setVideoLink(item.sourceLink);
//           }} className={styles.videoTitle} key={i}>
//             {item.title}
//             <p>{item.duration}</p>
//           </button>
//         )}

//       {modal ? (
//         <VideoBox closeModal={closeModal} videoLink={videoLink} />

//       ) : null}
//       <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Oxford </div>
//       {
//         videoList_6.map((item, i) =>
//           <button onClick={() => {
//             setModal(!modal);
//             setVideoLink(item.sourceLink);
//           }} className={styles.videoTitle} key={i}>
//             {item.title}
//             <p>{item.duration}</p>
//           </button>
//         )}

//       {modal ? (
//         <VideoBox closeModal={closeModal} videoLink={videoLink} />

//       ) : null}
//       <div className={`secondaryTitle ${styles.videoGroupTitle}`}>The Cotswolds</div>
//       {
//         videoList_7.map((item, i) =>
//           <button onClick={() => {
//             setModal(!modal);
//             setVideoLink(item.sourceLink);
//           }} className={styles.videoTitle} key={i}>
//             {item.title}
//             <p>{item.duration}</p>
//           </button>
//         )}

//       {modal ? (
//         <VideoBox closeModal={closeModal} videoLink={videoLink} />

//       ) : null}
//       <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Bath </div>
//       {
//         videoList_8.map((item, i) =>
//           <button onClick={() => {
//             setModal(!modal);
//             setVideoLink(item.sourceLink);
//           }} className={styles.videoTitle} key={i}>
//             {item.title}
//             <p>{item.duration}</p>
//           </button>
//         )}

//       {modal ? (
//         <VideoBox closeModal={closeModal} videoLink={videoLink} />

//       ) : null}


//     </div>

//   );
// };

// export default VideoStudy;

function VideoStudy() {
  return (
    <div className="pageContainer emptyDiv">
      <p className="title">Information to come ...</p>
    </div>
  );
}

export default VideoStudy;