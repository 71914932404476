import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
import picture_4 from "../../img/Greece/Day_4.jpg";
import picture_5 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
import picture_6 from "../../img/Greece/Day 5 Thessalonika.jpeg";
import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itinerary from "../../img/Greece/Tour-of-Greece-Sept-5-16-2024.pdf";
import { IoClose } from "react-icons/io5";

function GreeceItinerarySeptember() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">September 5 - 16, 2024</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Thursday - Sept 5</h2>
        <p className="text">Depart the USA</p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Friday - Sept 6</h2>
        <p className="text">
          Arrive in Greece and travel the 30-minute drive into Athens. Get
          settle into our hotel and explore the surrounding area in the heart of
          Athens (overnight in Athens).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 2*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div>
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Saturday - Sept 7</h2>
        <p className="text">
          Athens: the Ancient Agora (Marketplace), Areopagus Hill (Mars Hill),
          the Acropolis of Athens and the Parthenon. Stroll through the Plaka
          (overnight in Athens).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button>
        </p>
        {/*Box 3*/}
        <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Athens: we will stroll through the Ancient Agora (Marketplace)
              visiting the Stoa of Attalos, a stoa lined with shops built in the
              2nd century B.C. which has since been reconstructed for use as the
              Museum of the Ancient Agora. Here we visit many other ancient
              ruins/buildings of ancient Athens. This is where philosophers like
              Socrates gave advice to “know thyself,” and Zeno the Stoic
              lectured on how to control one&apos;s emotions. This is also
              considered to be the seat of western democracy.
            </p>
            <p className="text">
              Our day in Athens will include walking up to the Areopagus
              (Mar&apos;s Hill) a prominent rock outcropping located halfway up
              the hill to the Parthenon from the Agora. Acts 17:16-34
              prominently features the Areopagus as the setting for the Apostle
              Paul's Areopagus sermon to the philosophers during his visit to
              Athens, notably leading to the conversion of Dionysius the
              Areopagite. We will have a Bible study here from Acts 17:16-34.
            </p>
            <p className="text">
              Climbing a bit higher we will view Athens from the Acropolis and
              see the famous Parthenon, a former temple that was dedicated to
              the goddess Athena during the fifth century BC.
            </p>
            <p className="text">
              Coming down from the Acropolis a stroll through the charming Plaka
              and a delicious meal here will cap off our day. The Pláka is the
              old historical neighborhood of Athens, clustered around the
              northern and eastern slopes of the Acropolis, and incorporating
              labyrinthine streets and neoclassical architecture. Plaka is built
              on top of the residential areas of the ancient town of Athens.
            </p>
          </div>
        </div>
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Sunday - Sept 8</h2>
        <p className="text">
          Drive to Mt. Olympus with stops at Marathon, the place of the 300
          Spartans story (overnight in Litochora — foot of Mt.
          Olympus).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 4*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            On this day, we will drive to base of the highest mountain in
            Greece, Mt. Olympus, making stops at Marathon, and Thermopylae
            (scene of the famous 300 Spartans).
          </p>
          <p className="text">
            Marathon is a small town which was the site of the Battle of
            Marathon (490 BCE), in which the heavily outnumbered Athenian army
            defeated the Persians. It’s believed that Pheidippides, a Greek
            herald at the battle, was sent running from Marathon to Athens to
            announce the victory, which is how the marathon running race was
            conceived in modern times. But there is a lot more to this story and
            you will learn it.
          </p>
          <p className="text">
            Thermopylae was the scene of famous battle (480 BCE) in central
            Greece at a mountain pass during the Persian Wars. The Greek forces,
            mostly Spartan, were led by Leonidas. After three days of holding
            their own against the Persian king Xerxes I and his vast
            southward-advancing army, the Greeks were betrayed, and the Persians
            were able to outflank them. Sending the main army in retreat,
            Leonidas and a small contingent remained behind to resist the
            advance and were defeated.
          </p>
          <p className="text">
            In the evening we will settle into our lodging quarters at the foot
            of Mt. Olympus in the small charming town of Litochoro.
          </p>
        </div>
      </div>
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Monday - Sept 9</h2>
        <p className="text">
          Today we will relax in the beautiful village of Litochoro. For those who desire, we will hike
          up to the waterfalls above the village in the direction of Mt. Olympus (overnight in
          Litochoro).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 5*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Mt. Olympus"
          />
          <p className="text">
            Mt. Olympus is the highest mountain in Greece at 9,573 feet. In Greek mythology, Olympus
            was regarded as the abode of the gods and the site of the throne of Zeus. On this day, we will
            also have a Bible study from I Thessalonians and Acts 17:1-9 (Paul’s time in Thessaloniki
            where we will be the next day).
          </p>
        </div>
      </div>
      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Tuesday - Sept 10</h2>
        <p className="text">
          Travel to Thessaloniki: Thessaloniki (pop. 814,000) is called the second capital by the
          Greeks, since it is not only the second largest city of Greece after Athens, but also the capital
          of Northern Greece and Macedonia. It is beautifully situated by the sea (overnight in Kavala).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 6*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            We will visit the ruins of the ancient city where Paul founded a church, stroll along the
            harbour, take a boat ride in the harbor/gulf (Aegean Sea), and view the statute of Alexander
            the Great
          </p>
          <p className="text">Leaving Thessaloniki we will travel east to Kavala (near to the ancient ruins of Philippi).
            After settling into our lodgings, we will have time to stroll along the harbor/port in Kavala
            (biblical Neapolis), the one Paul sailed into from Troas in Turkey/Asia Minor after he
            received the “Macedonian vision” (Acts 16:6-12).</p>
        </div>
      </div>
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7, Wednesday - Sept 11</h2>
        <p className="text">
          Tour the archaeological site of ancient Philippi and have a Bible
          study from Philippians on the top of the acropolis of Philippi
          (overnight in Kavala).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 7*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            Tour the archaeological site of ancient Philippi where Paul and
            Silas were imprisoned for casting a spirit of divination out of a
            young slave girl and where they baptized their first European
            convert, Lydia, by the stream beside the town. We will have a Bible
            study from Philippians on the top of the acropolis of Philippi where
            we will enjoy a gorgeous view of the Macedonian mountains and plain
            below the acropolis where the great Roman civil war battle took
            place (42 BC). Here the forces of Octavius (Caesar Augustus) and
            Mark Anthony defeated the legions loyal to Brutus and Cassius, Roman
            senators, who were instigators in the murder of Julius Caesar two
            years earlier. This battle changed the ancient world forever as Rome
            was transformed into empire from a republic.
          </p>
        </div>
      </div>
      {/*Day 8*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8, Thursday - Sept 12</h2>
        <p className="text">
          Late afternoon we will arrive to the stunningly beautiful area of Meteora and stay in a small
          town nearby. Speaking for both Martha and me, this is definitely one of
          our favorite places in all of Greece (overnight in Kalabaka).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 8*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            This will be a travel day to from Philippi to Meteora in the
            mountains of central Greece with a stop in Berea for lunch. It is
            believed that Paul was in the area where we will visit an ancient
            synagogue.
          </p>
          <p className="text">
            Berea is the place mentioned in Acts 17:10-12, “As soon as it was
            night, the believers sent Paul and Silas away to Berea. On arriving
            there, they went to the Jewish synagogue. Now the Berean Jews were
            of more noble character than those in Thessalonica, for they
            received the message with great eagerness and examined the
            Scriptures every day to see if what Paul said was true. As a result,
            many of them believed, as did also a number of prominent Greek women
            and many Greek men.
          </p>
          <p className="text">
            Late afternoon we will arrive to the stunningly beautiful area of
            Meteora and stay in the small town of Kastraki. Speaking for both
            Martha and me, this is definitely one of our favorite places in all
            of Greece.
          </p>
        </div>
      </div>
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Friday - Sept 13</h2>
        <p className="text">
          Meteora: visit the stunning monasteries built on the cliffs of central
          Greece (overnight in Kalabaka).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(9);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 9*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[9] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(9);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_9}
            alt="Meteora Monastery"
          />
          <p className="text">
            Meteora: Our visit here will take in at least two of the stunning
            monasteries built on the cliffs of central Greece. There are six
            Meteora monasteries perched on the huge cliffs people can visit
            today. Serene, mystical, extraordinary, breathtaking, immense,
            inspiring, impressive. These are only some of the words people very
            often use in an effort to describe the Meteora monasteries.
          </p>
        </div>
      </div>
      {/*Day 10*/}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Saturday - Sept 14</h2>
        <p className="text">
          Travel to Corinth with a stop and lunch at Actium, where the great
          battle between Octavian and Mark Anthony took place (overnight in ancient Corinth).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 10*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            This day we will drive to Corinth with a stop and lunch at the
            strait of Actium where Octavian gained his celebrated victory over
            the forces Mark Antony and Cleopatra in 31 BC. This naval battle
            resulted in Octavias (Augustus Caesar) being the sole emperor of the
            Roman Empire. This same man would later give the degree that moved
            Joseph and Mary to Bethlehem where Jesus Christ was born fulfilling
            the prophecy of Micah the prophet. We will also visit the camp of
            Octavian, and where he later set up a memorial to himself for
            winning that historic battle
          </p>
        </div>
      </div>
      {/*Day 11*/}
      <div>
        <h2 className={styles.dayNumber}>Day 11, Sunday - Sept 15</h2>
        <p className="text">
          Tour the ancient site of Corinth, hike on Acro-Corinth, walk over the
          bridge spanning the Corinth Canal (overnight in ancient Corinth).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(11);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 11*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[11] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(11);
            }}
          />
          <img className={styles.pictureFloat} src={picture_11} alt="Corinth" />
          <p className="text">
            Tour the ancient site of Corinth where Paul founded a church and
            spent at least 18 months on his second missionary journey. The
            church in Corinth is where Paul directed two of his epistles. We
            will stand at the “bema” (seat of judgment), the place Paul stood
            before the proconsul of Achaia, Gallio, as recorded in Acts
            18:12-17. Later we will drive up to and then hike on the mountain
            fortress of Acco-Corinth. Later in the day we will walk over the
            bridge spanning the Corinth Canal.
          </p>
        </div>
      </div>
      {/*Day 12*/}
      <div>
        <h2 className={styles.dayNumber}>Day 12, Monday - Sept 16</h2>
        <p className="text">Depart Greece</p>
      </div>
    </div>
  );
}

export default GreeceItinerarySeptember;
