import React, { useState } from "react";
import styles from "./../../Styles/VideoStudy.module.scss";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import VideoBox from "../../Components/VideoBox";


const videoList_1 = [
  {
    id: 1,
    title: "How the Rome Mamertine Prison That Once Held Sts. Peter and Paul in Captivity Became a Holy Site",
    duration: "3:12 mins",
    sourceLink: "https://www.youtube.com/embed/5_0mrDQ9-P8?si=1eZPdyHAxx91_IHx"
  },
  {
    id: 2,
    title: "Mamertine Prison – Part 1 of 3 – The view of the outside of the prison",
    duration: "4:31 mins",
    sourceLink: "https://www.youtube.com/embed/P4Gb0tWvYx4?si=t-9kPpUEmIzFWxJB"
  },
  {
    id: 3,
    title: "Mamertine Prison – Part 2 of 3 – Inside on an upper level",
    duration: "14:13 mins",
    sourceLink: "https://www.youtube.com/embed/FdF7IJ6yLRQ?si=hdGhk7AEHGc_Xldi"
  },
  {
    id: 4,
    title: "Mamertine Prison – Part 3 of 3 – Going down to the jail",
    duration: "7:01 mins",
    sourceLink: "https://www.youtube.com/embed/bUtJcT2KnfM?si=YOrq2cuSBL8Cx8vD"
  },
  {
    id: 5,
    title: "The Last Days of Paul, the Apostle: In Pursuit of Paul / Episode 7",
    duration: "21:20 mins",
    sourceLink: "https://www.youtube.com/embed/NuUQ--dNUYU?si=NW2-uHiVSeE8aaYP"
  },
  {
    id: 6,
    title: "The Apostle Paul – The Top Ten Archaeological Discoveries: Digging for Truth Episode 139",
    duration: "26:09 mins",
    sourceLink: "https://www.youtube.com/embed/-ucqHF_JVRw?si=hEQyj3q5WtM_qhF-"
  }
]
const videoList_2 = [
  {
    id: 1,
    title: "The Entire Roman Empire Timeline Explained",
    duration: "16:16 mins",
    sourceLink: "https://www.youtube.com/embed/XUlRzFSESYs?si=_FKy9-kkPFl1iS22"
  },
  {
    id: 2,
    title: "Ancient Rome in 20 minutes",
    duration: "20:58 mins",
    sourceLink: "https://www.youtube.com/embed/46ZXl-V4qwY?si=aVu3bozbuwUXTNrb"
  },
  {
    id: 3,
    title: "Rome: Rick Steves",
    duration: "54:59 mins",
    sourceLink: "https://www.youtube.com/embed/Ra3h6yKLSq4?si=CNuYqupFbakTQNaz"
  },
  {
    id: 4,
    title: "Rome: Ancient Glory Rick Steves",
    duration: "24:57 mins",
    sourceLink: "https://www.youtube.com/embed/PKKMCcaoUkc?si=9ccCe2L3wFTlKOo1"
  },
  {
    id: 5,
    title: "Megapolis – The Ancient World Revealed / Episode 4: Rome",
    duration: "52:15 mins",
    sourceLink: "https://www.youtube.com/embed/Yq3dw_I1aW4"
  },
  {
    id: 6,
    title: "How the Roman Colosseum Was Built: Weird History",
    duration: "11:18 mins",
    sourceLink: "https://www.youtube.com/embed/09meiYkTsBo?si=FnQn-sxFE8j5xSRE"
  },
  {
    id: 7,
    title: "How Did the Ancient Romans Actually Dress",
    duration: "10:46 mins",
    sourceLink: "https://www.youtube.com/embed/EJpEfB9xeP4?si=G3C3iOMbVwqIwZff"
  },
  {
    id: 8,
    title: "What Caused the Fall of the Roman Empire",
    duration: "4:03 mins",
    sourceLink: "https://www.youtube.com/embed/-b99aSnhep4?si=CX--14o3zi2BnTo5"
  }
]
const videoList_3 = [
  {
    id: 1,
    title: "The Sistine Chapel – (With Surprising Michelangelo Facts!)",
    duration: "6:24 mins",
    sourceLink: "https://www.youtube.com/embed/sJR8qHZwbyI?si=ftKxAQCspvF8_29J"
  },
  {
    id: 2,
    title: "Vatican City – The Holy Grail for Travelers: History, Attractions, Tips",
    duration: "13:30 mins",
    sourceLink: "https://www.youtube.com/embed/s6vgvKneoIk?si=WB_1PgCqkuhi12y_"
  },
  {
    id: 3,
    title: "St Peter’s Basilica Explained",
    duration: "21:17 mins",
    sourceLink: "https://www.youtube.com/embed/O2um7D1veeY?si=ViMtSgNJkyVH-PS4"
  },
  {
    id: 4,
    title: "Peter: Calling, Consecration, and Crucifixion / Pentecost to Patmos / Episode 14 / Lineage",
    duration: "5:36 mins",
    sourceLink: "https://www.youtube.com/embed/x2rlUw1XceA?si=JAkD-kt5j4hEgSCH"
  },
  {
    id: 5,
    title: "Was Peter in Rome? (Yes, Peter was in Rome!)",
    duration: "14:33 mins",
    sourceLink: "https://www.youtube.com/embed/2wCXqh3VpW8?si=NLgKvQxsfgl3663F"
  },
  {
    id: 6,
    title: "Roman Explorations I: The Christian Catacombs of Rome",
    duration: "21:30 mins",
    sourceLink: "https://www.youtube.com/embed/pB_jJmcsDyQ?si=U4HRoOHfm5buyrST"
  }
]
const videoList_4 = [
  {
    id: 1,
    title: "The Eruption of Mount Vesuvius: Pompeii’s Tragic End",
    duration: "9:45 mins",
    sourceLink: "https://www.youtube.com/embed/g3Tagf2tNX4?si=ImCb4QAPbPgBuiC_"
  },
  {
    id: 2,
    title: "Mount Vesuvius Eruption (79 AD): The First 24 hrs – Survival and Rescue Documentary",
    duration: "19:48 mins",
    sourceLink: "https://www.youtube.com/embed/TENWJTq4aM8?si=yenC2JY8Y9k0Qm28"
  },
  {
    id: 3,
    title: "Pompeii: Inside the Ancient City That Was Buried Alive",
    duration: "48:03 mins",
    sourceLink: "https://www.youtube.com/embed/WITAiHXppMI?si=3ukb18Q2avyDt35L"
  },
  {
    id: 4,
    title: "Italy’s Amalfi Coast: Rick Steves",
    duration: "25:05 mins",
    sourceLink: "https://www.youtube.com/embed/1rx9dWL6cAc?si=ikceg-v37maJzGKz"
  }
]
const videoList_5 = [
  {
    id: 1,
    title: "The Life of Julius Caesar – The Rise and Fall of a Roman Colossus",
    duration: "37:33 mins",
    sourceLink: "https://www.youtube.com/embed/xuHwfm2lHrk?si=IpB-Z_bW06NYflPG"
  },
  {
    id: 2,
    title: "Augustus: Rome’s Greatest Emperor",
    duration: "44:58 mins",
    sourceLink: "https://www.youtube.com/embed/Zw9le2od08k?si=YkNGfDaFGh4zCjqR"
  },
  {
    id: 3,
    title: "Most Sadistic Things Nero Ever Did",
    duration: "11:25 mins",
    sourceLink: "https://www.youtube.com/embed/AZuyahs_pto?si=utHKg-x1ktWD8Gij"
  },
  {
    id: 4,
    title: "Nero – The Evil Roman Emperor Documentary",
    duration: "1:01:10 mins",
    sourceLink: "https://www.youtube.com/embed/8oWddO2Zs6g?si=r9D0Es86bdGb80Zu"
  },
  {
    id: 5,
    title: "Who Were the 12 Roman Emperors of the 1st Century",
    duration: "17:18 mins",
    sourceLink: "https://www.youtube.com/embed/U5dfeAbCpmA?si=dYgaN_-kxlMy7Emp"
  },
  {
    id: 6,
    title: "Roman Emperor: Hadrian (117-138 AD)",
    duration: "7:45 mins",
    sourceLink: "https://www.youtube.com/embed/QIHU-mdSs-Q?si=CKtPNn2RibOL46_8"
  }
]
function VideoStudy() {

  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  //const [videoLoading, setVideoLoading] = useState(true);

  const closeModal = () => {
    setModal(!modal);
  };


  return (
    <div className="pageContainer">
      <div className="title">Documentaries for the Italy Tour
      </div>
      <p className="text">You are invited/encouraged to watch these documentaries in the months prior to departure. It will add greatly to your experience/learning. I do not endorse everything said in these documentaries, but for the most part, I do.
      </p>
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>The Apostle Paul in Rome</div>
      {
        videoList_1.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Rome and the Roman Empire</div>
      {
        videoList_2.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>The Vatican, St Peter&apos;s Basilica, the Apostle Peter, and the Catacombs</div>
      {
        videoList_3.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Mt Vesuvius, Pompeii, the Amalfi Coast and Capri</div>
      {
        videoList_4.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Roman Emperors</div>
      {
        videoList_5.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />

      ) : null}

    </div>

  );
};

export default VideoStudy;



{/* <section className={styles.modal__bg}>
          <div className={styles.modal__align}>
            <IoCloseOutline
              className={styles.modal__close}
              arial-label="Close modal"
              onClick={openModal}
            />
            <iframe
              //className="modal__video-style"
              loading="lazy"
              width="100%"
              height="100%"
              src={videoList_1[1].sourceLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen />

          </div>
        </section> */}
