function Scriptureguide() {
  return (
    <div className="pageContainer emptyDiv">
      <p className="title">Information to come ...</p>
    </div>
  );
}

export default Scriptureguide;

