import "../../Styles/Galery.scss";
import React from "react";

import picture_1 from "../../img/Greece/Galery_1.jpeg";
import picture_2 from "../../img/Greece/Galery_2.jpeg";
import picture_3 from "../../img/Greece/Galery_3.jpeg";
import picture_4 from "../../img/Greece/Galery_4.jpeg";
import picture_5 from "../../img/Greece/Galery_5.jpg";
import picture_6 from "../../img/Greece/Galery_6.jpg";
import picture_7 from "../../img/Greece/Gr_Prayer_1.jpeg";
import picture_8 from "../../img/Greece/Gr_Prayer_2.jpeg";
import picture_9 from "../../img/Greece/Gr_Prayer_3.jpeg";
import picture_10 from "../../img/Greece/Galery_13.jpeg";
import picture_11 from "../../img/Greece/Galery_7.jpeg";
import picture_12 from "../../img/Greece/Galery_8.jpeg";
import picture_13 from "../../img/Greece/Galery_9.jpeg";
//import picture_14 from "../../img/Greece/Galery_10.jpeg";
import picture_15 from "../../img/Greece/Galery_11.jpg";
import picture_16 from "../../img/Greece/Galery_12.jpg";
import original_1 from "../../img/Greece/Original_1.jpeg";
import original_2 from "../../img/Greece/Original_2.jpeg";
import original_3 from "../../img/Greece/Original_3.jpeg";
import original_4 from "../../img/Greece/Original_4.jpeg";
import original_5 from "../../img/Greece/Original_5.jpg";
import original_6 from "../../img/Greece/Original_6.jpg";
import original_7 from "../../img/Greece/Original_14.jpeg";
import original_8 from "../../img/Greece/Original_15.jpeg";
import original_9 from "../../img/Greece/Original_16.jpeg";
import original_10 from "../../img/Greece/Original_13.jpeg";
import original_11 from "../../img/Greece/Original_7.jpeg";
import original_12 from "../../img/Greece/Original_8.jpeg";
import original_13 from "../../img/Greece/Original_9.jpeg";
//import original_14 from "../../img/Greece/Original_10.jpeg";
import original_15 from "../../img/Greece/Original_11.jpg";
import original_16 from "../../img/Greece/Original_12.jpg";

import ImageGallery from "react-image-gallery";
const images = [
  {
    original: original_1,
    thumbnail: picture_1,
  },
  {
    original: original_2,
    thumbnail: picture_2,
  },
  {
    original: original_3,
    thumbnail: picture_3,
  },
  {
    original: original_4,
    thumbnail: picture_4,
  },
  {
    original: original_5,
    thumbnail: picture_5,
  },
  {
    original: original_6,
    thumbnail: picture_6,
  },
  {
    original: original_7,
    thumbnail: picture_7,
  },
  {
    original: original_8,
    thumbnail: picture_8,
  },
  {
    original: original_9,
    thumbnail: picture_9,
  },
  {
    original: original_10,
    thumbnail: picture_10,
  },
  {
    original: original_11,
    thumbnail: picture_11,
  },
  {
    original: original_12,
    thumbnail: picture_12,
  },
  {
    original: original_13,
    thumbnail: picture_13,
  },

  {
    original: original_15,
    thumbnail: picture_15,
  },
  {
    original: original_16,
    thumbnail: picture_16,
  },
];

function GreeceGalery() {
  return (
    <div className="pageContainer space">
      <div className="gallery-container">
        <div className="image-gallery-wrapper pictures">
          <ImageGallery
            items={images}
            disableThumbnailScroll={true}
            showThumbnails={false}
            showBullets={true}
          />
        </div>
      </div>
    </div>
  );
}

export default GreeceGalery;
