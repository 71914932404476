import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPictureIsrael}>
      <div className={styles.titleBox}>
        <h1>Go to the historical land of</h1>
        <p className={styles.title}>Israel</p>
        <p className={styles.dates}>May 3 - 16, 2024</p>
        <div className={styles.description}>
          <p>Visit the great biblical archeological sites.</p>
          <p>Deepen your walk with God going on the spiritual pilgrimage.</p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;