import styles from "./PageInMaking.module.scss";
import { NavLink } from "react-router-dom";

function PageInMaking() {
  return (
    <div className={styles.message}>
      <h1 className="title">Trip details are in making ...</h1>
      <NavLink className={styles.goBack} to="/">
        Back to main menu
      </NavLink>
    </div>
  );
}

export default PageInMaking;
