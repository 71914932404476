import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPictureSwitz}>
      <div className={styles.titleBox}>
        <h1>Come to what many consider to be  the most beautiful country in the world,</h1>
        <p className={styles.title}>Switzerland</p>
        <p className={styles.dates}>August 17 - 28, 2024</p>
        <div className={styles.description}>
          <p>Zurich, Lucerne, Lake Lucerne, Mount Pilatus, Lauterbrunnen and Murren in the Alps: </p>
          <p>Switzerland's spectacular landscape includes snow-capped Alps and grassy hills spotted with grazing cows. It is home to 1,500 sparkling, crystal-clear lakes.</p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;