import React, { useState } from "react";
import styles from "./../../Styles/VideoStudy.module.scss";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import VideoBox from "../../Components/VideoBox";


const videoList_1 = [
  {
    id: 1,
    title: "History of Turkey in 10 minutes",
    duration: "10:41 mins",
    sourceLink: "https://www.youtube.com/embed/sp8J4za_3GI?si=1T8F7BT7YiTsVoe4"
  },
  {
    id: 2,
    title: "Everything You Need to Know Before Visiting Turkey 2024",
    duration: "13:25 mins",
    sourceLink: "https://www.youtube.com/embed/hgoXWQejBCQ?si=mJncVsOzPFyI8OlF"
  },
  {
    id: 3,
    title: "Understanding the Turkish Mindset",
    duration: "14:35 mins",
    sourceLink: "https://www.youtube.com/embed/Lg-TJO5yv2Q?si=HFK1ddympl3aaq3-"
  },
  {
    id: 4,
    title: "Why Hagia Sophia is So Important. The Whole History Explained",
    duration: "6:33 mins",
    sourceLink: "https://www.youtube.com/embed/v6BAPskdJXc?si=h0sDk2mWHgTj1AkW"
  },
  {
    id: 5,
    title: "11 Interesting Facts About Bosphorus Strait",
    duration: "3:02 mins",
    sourceLink: "https://www.youtube.com/embed/-PSCNTu2N-Q?si=aoG0rEZldbhCz3xx"
  }

]

const videoList_2 = [
  {
    id: 1,
    title: "Polycarp of Smyrna-The Complete Story/Documentary",
    duration: "12:14 mins",
    sourceLink: "https://www.youtube.com/embed/mIwFr1UE7Nk?si=kBPRU4Zf6TS74UPt"
  },
  {
    id: 2,
    title: "The Death of Polycarp",
    duration: "10:42 mins",
    sourceLink: "https://www.youtube.com/embed/z_fUAnd2FXo?si=Bl8sGv2bxMBM3XlY"
  },
  {
    id: 3,
    title: "Martyrdom of Polycarp / Full Audiobook",
    duration: "27:13 mins",
    sourceLink: "https://www.youtube.com/embed/C7pAig4xUKo?si=siFFh8ZwrPBRbseV"
  }

]
const videoList_3 = [
  {
    id: 1,
    title: "Patmos Island. Seven Churches of Revelation Tour",
    duration: "11:40 mins",
    sourceLink: "https://www.youtube.com/embed/VnF9tHGYoQ0?si=DYITOKUtn_Ujyt_a"
  },
  {
    id: 2,
    title: "Rob Bell: Jesus, Domitian and the Book of Revelation",
    duration: "56:33 mins",
    sourceLink: "https://www.youtube.com/embed/uThyuSy8CsM?si=cu3ORnSKqjBTWa4L"
  },
  {
    id: 3,
    title: "Biblical Turkey by Mark Wilson / Recommended Resource",
    duration: "3:15 mins",
    sourceLink: "https://www.youtube.com/embed/WojasN0eYdg?si=TGRl5jvuzNmCgkup"
  },
  {
    id: 4,
    title: "Christian Faith Tourism in Anatolia: Seven Churches and Paul’s Journeys by Dr. Mark Wilson",
    duration: "1hr:43 mins",
    sourceLink: "https://www.youtube.com/embed/kHFu-Qe3ZC0?si=XXdlCUCjVRswpsjE"
  },
  {
    id: 5,
    title: "Exploring Ephesus: City of Apostles (2015) | Full Movie | Dr. Mark Wilson | Dr. Andrew Jackson",
    duration: "58:30 mins",
    sourceLink: "https://www.youtube.com/embed/6sa3P4udi2I?si=Is7JKGuQ8Ay1JYJN"
  },
  {
    id: 6,
    title: "Dr. Mark Wilson - Smyrna among the Seven Churches | 1st Global Smyrna Meeting",
    duration: "52:41 mins",
    sourceLink: "https://www.youtube.com/embed/1T1P8pe4m94?si=2dVaWVuiimVT1Hkk"
  }
]


function VideoStudy() {

  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  //const [videoLoading, setVideoLoading] = useState(true);

  const closeModal = () => {
    setModal(!modal);
  };


  return (
    <div className="pageContainer">
      <div className="title">Documentaries for the Turkey Tour
      </div>
      <p className="text">You are invited/encouraged to watch these documentaries in the months prior to departure. It will add greatly to your experience/learning. I do not endorse everything said in these documentaries, but for the most part, I do.
      </p>
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Historical and cultural background</div>
      {
        videoList_1.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Polycarp of Smyrna</div>
      {
        videoList_2.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Biblical Turkey, Seven Churches and Paul’s Journeys</div>
      {
        videoList_3.map((item, i) =>
          <button onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }} className={styles.videoTitle} key={i}>
            {item.title}
            <p>{item.duration}</p>
          </button>
        )}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />

      ) : null}

    </div>

  );
};

export default VideoStudy;



{/* <section className={styles.modal__bg}>
          <div className={styles.modal__align}>
            <IoCloseOutline
              className={styles.modal__close}
              arial-label="Close modal"
              onClick={openModal}
            />
            <iframe
              //className="modal__video-style"
              loading="lazy"
              width="100%"
              height="100%"
              src={videoList_1[1].sourceLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen />

          </div>
        </section> */}
