import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
// import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
// import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
// import picture_4 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
// import picture_5 from "../../img/Greece/Day 5 Thessalonika.jpeg";
// import picture_6 from "../../img/Greece/Day 6 Kavala.jpeg";
// import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
// import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
// import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
// import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
// import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itinerary from "../../img/Switzerland/Itinerary.pdf";
import { IoClose } from "react-icons/io5";
import pictureOne from "./../../img/Switzerland/SwitzItin_1.jpeg";
import pictureTwo from "./../../img/Switzerland/SwitzItin_2.jpeg";
import pictureThree from "./../../img/Switzerland/SwitzItin_3.jpg";

function ItalyItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">August 17 - 28, 2024</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <p className="text">
        This 10-day trip to Switzerland will take you to Zurich, Lucerne, Schangnau, Lauterbrunnen, Murren, and Appenzell. Ride to the top of Mt. Pilatus and boat on Lake Lucerne. Gondolas, cogwheel trains, and cable cars will take you to vistas with mind-blowing views in the heart of the Swiss Alps. Learn about the Swiss Reformation, eat traditional Swiss food, and enjoy the fellowship of a small tour group.       </p>
      <div className="pictureRow">
        <div>
          <img
            className="picture"
            src={pictureOne}
            alt="Poland town"
          />
        </div>
        <div className="hide">
          <img
            className="picture"
            src={pictureTwo}
            alt="Poland solt mines"
          />
        </div>
        <div>
          <img
            className="picture"
            src={pictureThree}
            alt="Polamd mountains"
          />
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Saturday, August 17</h2>
        <p className="text">
          Depart the USA. Each person/couple will fly from their desired location in the USA to Zurich Airport.
        </p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Sunday, August 18</h2>
        <p className="text">
          Upon arrival to Switzerland, we will be staying in a hotel right at the airport. What is left of the day will be spent in resting, eating and visiting (staying in Zurich).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 2*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div> */}
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Monday, August 19</h2>
        <p className="text">
          Today we will take a tour of Zurich after which we will travel to the city of Lucerne
          (overnight in Lucerne).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button> */}
        </p>
        {/*Box 3*/}
        {/* <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Athens: we will stroll through the Ancient Agora (Marketplace)
              visiting the Stoa of Attalos, a stoa lined with shops built in the
              2nd century B.C. which has since been reconstructed for use as the
              Museum of the Ancient Agora. Here we visit many other ancient
              ruins/buildings of ancient Athens. This is where philosophers like
              Socrates gave advice to “know thyself,” and Zeno the Stoic
              lectured on how to control one&apos;s emotions. This is also
              considered to be the seat of western democracy.
            </p>
            
          </div>
        </div> */}
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Tuesday, August 20</h2>
        <p className="text">
          Discover the spectacular natural beauty of the Swiss Alps during this
          action-packed day trip from Lucerne to the top of Mt. Pilatus.
          We will take a panoramic gondola ride, a cogwheel train ride
          (the steepest in the world), and a cruise on Lake Lucerne making this an exceptional day
          (overnight in Lucerne).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 4*/}
      {/* <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            On this day, we will drive to base of the highest mountain in
            Greece, Mt. Olympus, making stops at Marathon, and Thermopylae
            (scene of the famous 300 Spartans).
          </p>
          <p className="text">
            Marathon is a small town which was the site of the Battle of
            Marathon (490 BCE), in which the heavily outnumbered Athenian army
            defeated the Persians. It’s believed that Pheidippides, a Greek
            herald at the battle, was sent running from Marathon to Athens to
            announce the victory, which is how the marathon running race was
            conceived in modern times. But there is a lot more to this story and
            you will learn it.
          </p>
          
        </div>
      </div> */}
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Wednesday, August 21</h2>
        <p className="text">
          After breakfast and some shopping in Lucerne, we will travel to the lovely village of
          Schangnau. We will make a stop in the area and learn about the Anabaptists (Reformation)
          and Jews who came from this area. Dan Glick’s ancestors came from this beautiful valley.
          Toward evening we will travel on to our hotel near Thun which will have a view of Lake
          Thun below (overnight near Thun).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 5*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Thessalonica"
          />
          <p className="text">
            Travel to Thessalonica: Thessaloniki (pop. 814,000) is called the
            second capital by the Greeks, since it is not only the second
            largest city of Greece after Athens, but also the capital of
            Northern Greece and Macedonia. It is beautifully situated by the
            sea.
          </p>
          <p className="text">
            We will visit the ruins of the ancient city where Paul founded a
            church, stroll along the harbour, take a boat ride in the
            harbor/gulf (Aegean Sea), and view the statute of Alexander the
            Great
          </p>
        </div>
      </div> */}
      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Thursday, August 22</h2>
        <p className="text">
          In the morning we will drive to Thun and alongside Lake Thun to
          Interlaken and then on to Lauterbrunnen in the heart of the Alps.
          After grabbing some lunch and viewing the gorgeous falls at Lauterbrunnen,
          we will park in the valley and take a cable car (no cars can access this place)
          to the village of Murren in the Alps. We will stay in this village with its absolutely
          incredible views over the next couple days (overnight in Murren).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 6*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            Leaving Thessalonika we will travel east to Philippi: After settling
            into our lodgings, we will travel eight miles to the coast and eat
            at the harbour in Kavala (biblical Neapolis), the one Paul sailed
            into from Troas in Turkey/Asia Minor after he received the
            “Macedonian vision” (Acts 16:6-12).
          </p>
        </div>
      </div> */}
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7-8, Friday-Saturday, August 23-24</h2>
        <p className="text">
          These two relaxing days will be filled with hikes, and if you desire, an incredible cable car ride that takes you from the mountain village of Mürren to Birg station at 8,782 feet above sea level, where you change into another cable car that takes you to the Schilthorn's Piz Gloria restaurant at 9,744 feet above sea level. Prepare for astounding views and time to spend just taking in some of the most beautiful sights in this world (staying in Murren).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 7*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            Tour the archaeological site of ancient Philippi where Paul and
            Silas were imprisoned for casting a spirit of divination out of a
            young slave girl and where they baptized their first European
            convert, Lydia, by the stream beside the town. We will have a Bible
            study from Philippians on the top of the acropolis of Philippi where
            we will enjoy a gorgeous view of the Macedonian mountains and plain
            below the acropolis where the great Roman civil war battle took
            place (42 BC). Here the forces of Octavius (Caesar Augustus) and
            Mark Anthony defeated the legions loyal to Brutus and Cassius, Roman
            senators, who were instigators in the murder of Julius Caesar two
            years earlier. This battle changed the ancient world forever as Rome
            was transformed into empire from a republic.
          </p>
        </div>
      </div> */}
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Sunday, August 25</h2>
        <p className="text">
          Taking a very scenic route, we will drive leisurely to eastern Switzerland, making several stops along the way. We will lodge for the next two nights in area around Appenzell, which is a very traditional Swiss area. We will have a Bible study together on this day (staying in Appenzell).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 9*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            This will be a travel day to from Philippi to Meteora in the
            mountains of central Greece with a stop in Berea for lunch. It is
            believed that Paul was in the area where we will visit an ancient
            synagogue.
          </p>
          
        </div>
      </div> */}

      {/* day 10 */}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Monday, August 26</h2>
        <p className="text">
          Take the cable car up Ebenalp, a beautiful mountain with outstanding views and hiking trails just south of Appenzell. We will eat at an outdoor restaurant right on the cliff overlooking the mountains and the valley below. Later in the day we will spend time in the town of Appenzell (staying in Appenzell) .

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/* Day 11 */}
      <div>
        <h2 className={styles.dayNumber}>Day 11, Tuesday, August 27</h2>
        <p className="text">
          A leisurely drive back to Zurich with some stops on the way. Yet be determined (staying in Zurich).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>

      {/*Day 12*/}
      <div>
        <h2 className={styles.dayNumber}>Day 12, Wednesday, August 28</h2>
        <p className="text">
          Fly back to the USA

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 10*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            This day we will drive to Corinth with a stop and lunch at the
            strait of Actium where Octavian gained his celebrated victory over
            the forces Mark Antony and Cleopatra in 31 BC. This naval battle
            resulted in Octavias (Augustus Caesar) being the sole emperor of the
            Roman Empire. This same man would later give the degree that moved
            Joseph and Mary to Bethlehem where Jesus Christ was born fulfilling
            the prophecy of Micah the prophet. We will also visit the camp of
            Octavian, and where he later set up a memorial to himself for
            winning that historic battle
          </p>
        </div>
      </div> */}

    </div>
  );
}

export default ItalyItinerary;
