import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
// import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
// import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
// import picture_4 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
// import picture_5 from "../../img/Greece/Day 5 Thessalonika.jpeg";
// import picture_6 from "../../img/Greece/Day 6 Kavala.jpeg";
// import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
// import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
// import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
// import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
// import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itinerary from "../../img/England/Itinerary_England.pdf";
import { IoClose } from "react-icons/io5";
import pictureOne from "./../../img/England/Itin_1.jpeg";
import pictureTwo from "./../../img/England/Itin_3.jpeg";
import pictureThree from "./../../img/England/Itin_4.jpeg";

function ItalyItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">October 7 - 17, 2024</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      {/* <p className="text">
        This 10-day trip to Switzerland will take you to Zurich, Lucerne, Schangnau, Lauterbrunnen, Murren, and Appenzell. Ride to the top of Mt. Pilatus and boat on Lake Lucerne. Gondolas, cogwheel trains, and cable cars will take you to vistas with mind-blowing views in the heart of the Swiss Alps. Learn about the Swiss Reformation, eat traditional Swiss food, and enjoy the fellowship of a small tour group.       </p> */}
      <div className="pictureRow">
        <div>
          <img
            className="picture"
            src={pictureOne}
            alt="Poland town"
          />
        </div>
        <div className="hide">
          <img
            className="picture"
            src={pictureTwo}
            alt="Poland solt mines"
          />
        </div>
        <div>
          <img
            className="picture"
            src={pictureThree}
            alt="Polamd mountains"
          />
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Monday, October 7</h2>
        <p className="text">
          Depart the USA.
        </p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Tuesday, October 8</h2>
        <p className="text">
          Arrive in London and settle into our hotel, visit with the group, and rest.

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 2*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div> */}
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Wednesday, October 9</h2>
        <p className="text">
          London: Visit the Museum of Methodism, the Wesley Chapel, the John Wesley’s House, and the grave of John Wesley. Adam Clarke is also buried here. And John Bunyan is buried across the road.



          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button> */}
        </p>
        <p className="text">Other places as time permits, e.g., the Smithfield Martyrs Memorial.</p>


        {/*Box 3*/}
        {/* <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Athens: we will stroll through the Ancient Agora (Marketplace)
              visiting the Stoa of Attalos, a stoa lined with shops built in the
              2nd century B.C. which has since been reconstructed for use as the
              Museum of the Ancient Agora. Here we visit many other ancient
              ruins/buildings of ancient Athens. This is where philosophers like
              Socrates gave advice to “know thyself,” and Zeno the Stoic
              lectured on how to control one&apos;s emotions. This is also
              considered to be the seat of western democracy.
            </p>
            
          </div>
        </div> */}
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Thursday, October 10</h2>
        <p className="text">
          In the morning drive from London to Epworth where Samuel and Susannah Wesley pastored and raised their children.



          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button> */}
        </p>
        <p className="text">In the afternoon we will tour the house and grounds of the Wesley home. This is the house where John (at the age of 5) was saved as a “brand plucked from the burning” when the house caught fire.
        </p>
        <p className="text">In the evening, we will lodge at the quaint Red Lion Inn in Epworth where John Wesley stayed on occasion.
        </p>
      </div>
      {/*Box 4*/}
      {/* <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            On this day, we will drive to base of the highest mountain in
            Greece, Mt. Olympus, making stops at Marathon, and Thermopylae
            (scene of the famous 300 Spartans).
          </p>
          <p className="text">
            Marathon is a small town which was the site of the Battle of
            Marathon (490 BCE), in which the heavily outnumbered Athenian army
            defeated the Persians. It’s believed that Pheidippides, a Greek
            herald at the battle, was sent running from Marathon to Athens to
            announce the victory, which is how the marathon running race was
            conceived in modern times. But there is a lot more to this story and
            you will learn it.
          </p>
          
        </div>
      </div> */}
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Friday, October 11</h2>
        <p className="text">
          On this day, we will drive to Oxford, the home of the world-famous Oxford University where John and Charles Wesley were students. After getting settled in our hotel, we will begin our tour of Oxford.


          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 5*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Thessalonica"
          />
          <p className="text">
            Travel to Thessalonica: Thessaloniki (pop. 814,000) is called the
            second capital by the Greeks, since it is not only the second
            largest city of Greece after Athens, but also the capital of
            Northern Greece and Macedonia. It is beautifully situated by the
            sea.
          </p>
          <p className="text">
            We will visit the ruins of the ancient city where Paul founded a
            church, stroll along the harbour, take a boat ride in the
            harbor/gulf (Aegean Sea), and view the statute of Alexander the
            Great
          </p>
        </div>
      </div> */}
      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Saturday, October 12</h2>
        <p className="text">
          This day, our tour of Oxford will include visiting Christ Church at Oxford where Wesley was as an undergraduate student in June 1720.  Here is where he was ordained deacon in Christ Church Cathedral.


          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button> */}
        </p>
        <p className="text">We will also visit Magdalen College (part of Oxford University) where C.S Lewis was a professor and we will walk “Addison’s Walk, a picturesque footpath around a small island in the River Cherwell in the grounds of Magdalen College. It was a favored walk of C.S. Lewis.
        </p>
        <p className="text">We will visit other places as time permits.
        </p>
      </div>
      {/*Box 6*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            Leaving Thessalonika we will travel east to Philippi: After settling
            into our lodgings, we will travel eight miles to the coast and eat
            at the harbour in Kavala (biblical Neapolis), the one Paul sailed
            into from Troas in Turkey/Asia Minor after he received the
            “Macedonian vision” (Acts 16:6-12).
          </p>
        </div>
      </div> */}
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7, Sunday, October 13</h2>
        <p className="text">
          After leaving Oxford we will drive to Bath but make a stop in the beautiful Cotswolds where we will have lunch with our own food. In the evening, we will stroll around downtown Bath.

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 7*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            Tour the archaeological site of ancient Philippi where Paul and
            Silas were imprisoned for casting a spirit of divination out of a
            young slave girl and where they baptized their first European
            convert, Lydia, by the stream beside the town. We will have a Bible
            study from Philippians on the top of the acropolis of Philippi where
            we will enjoy a gorgeous view of the Macedonian mountains and plain
            below the acropolis where the great Roman civil war battle took
            place (42 BC). Here the forces of Octavius (Caesar Augustus) and
            Mark Anthony defeated the legions loyal to Brutus and Cassius, Roman
            senators, who were instigators in the murder of Julius Caesar two
            years earlier. This battle changed the ancient world forever as Rome
            was transformed into empire from a republic.
          </p>
        </div>
      </div> */}
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8, Monday, October 14</h2>
        <p className="text">
          We will visit nearby Bristol and discover the world’s oldest Methodist building in the center of Bristol, John Wesley’s New Room—the Room that changed the world. Three hundred years ago, John Wesley started a mission here that sparked a worldwide movement. We will visit his 18th-century chapel and the room where he lived while in Bristol.

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
        <p className="text">After this, we will drive across town to the George Mueller Estate, where you can sit at the desk where George Mueller studied his Bible and prayed with thousands of orphans.
        </p>
        <p className="text">Late afternoon we will drive to Wales where we will stay the night.</p>
      </div>
      {/*Box 9*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            This will be a travel day to from Philippi to Meteora in the
            mountains of central Greece with a stop in Berea for lunch. It is
            believed that Paul was in the area where we will visit an ancient
            synagogue.
          </p>
          
        </div>
      </div> */}

      {/* day 10 */}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Tuesday, October 15</h2>
        <p className="text">
          Our travels today will take us to Moriah Chapel, where the great Welsh revival of 1904-1905 broke out under the leadership of the young Evan Roberts. In this revival, 100,000 people were converted in just one year. We will visit Evan Roberts's grave on the church grounds.

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/* Day 11 */}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Wednesday, October 16</h2>
        <p className="text">
          Drive back to London/airport hotel with a stop or two as time permits. These stops may include Stonehenge and/or the White Cliffs of Beachy Head (similar to the White Cliffs of Dover).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>

      {/*Day 12*/}
      <div>
        <h2 className={styles.dayNumber}>Day 11, Thursday, October 17</h2>
        <p className="text">
          Fly back to the USA.

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 10*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            This day we will drive to Corinth with a stop and lunch at the
            strait of Actium where Octavian gained his celebrated victory over
            the forces Mark Antony and Cleopatra in 31 BC. This naval battle
            resulted in Octavias (Augustus Caesar) being the sole emperor of the
            Roman Empire. This same man would later give the degree that moved
            Joseph and Mary to Bethlehem where Jesus Christ was born fulfilling
            the prophecy of Micah the prophet. We will also visit the camp of
            Octavian, and where he later set up a memorial to himself for
            winning that historic battle
          </p>
        </div>
      </div> */}

    </div>
  );
}

export default ItalyItinerary;
