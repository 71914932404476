import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPictureGermany}>
      <div className={styles.titleBox}>
        <h1 className={styles.intro}>
          Come and explore the history of the events that changed the world.
        </h1>
        <p className={styles.title}>Germany</p>
        <p className={styles.dates}>May 26 - June 6, 2025</p>
        <div className={styles.description}>
          <p>
            Berlin, Herrnhut, Wittenberg, the Castle of Wartburg, Munich, the
            beauty of the Bavarian Alps, and much more.{" "}
          </p>
          <p>
            Discover the significant places and history behind the Protestant
            Reformation. Visit the home of Dietrich Bonhoeffer and the
            concentration camp where he was executed. Learn about the Moravians
            and how they launched the modern missionary era from Herrnhut.
          </p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
