import styles from "../../Styles/Generalinfo.module.scss";
import info from "../../img/Turkey/General_ Information_Turkey.pdf";

function Generalinfo() {
  const insurance = {
    href: "https://www.travelguard.com/",
    target: "_blank",
  };
  const visa = {
    href: "https://www.evisa.gov.tr/en/",
    target: "_blank",
  }

  return (
    <div className="pageContainer">
      <div className="title">General Information</div>
      <div className="printBox">
        <div className="print">
          <a href={info} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <div>
        <div className={styles.paragraphTitle}>TRIP DATES</div>
        <p className="text">September 22 – October 3, 2024
        </p>
      </div>

      <div>
        <div className={styles.paragraphTitle}>GROUP SIZE</div>
        <p className="text">20 - 30
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>COST</div>
        <p className="text">
          $2,330 plus airfare. Price is for double occupancy. Single occupancy adds $600. </p>

        <p className={`text ${styles.list}`}> <span>Price includes:</span>
          <ul>
            <li>2 nights at <span>Grand Hotel Halic</span> in Istanbul</li>
            <li>3 nights at <span>Ilayda Hotel</span> in Kusadasi </li>
            <li>1 night at <span>Kordon Cankaya Hotel</span> in Izmir </li>
            <li>1 night at <span>Lydia Thermal Hotel</span> in Salihli </li>
            <li>1 night at <span>Doga Thermal Hotel</span> in Pamukkale</li>
            <li>2 nights at <span>Best Western Khan</span> in Antalya</li>
            <li>Daily breakfasts at the hotels (B:Breakfast)</li>
            <li>7 dinners at the hotels (D:Dinner)</li>
            <li>All transportation by private AC Bus</li>
            <li>English-speaking, government-licensed, Bible-oriented guides</li>
            <li>All entry fees to the sites and museums</li>
            <li>Sightseeing as per the itinerary</li>
            <li>Service charges and local taxes</li>
            <li>Tips to tour guide ($5 per person per day) and drivers ($2 per person per day)</li>
          </ul>
        </p>
        <p className={`text ${styles.list}`}> <span>Price excludes:</span>
          <ul>
            <li>All flights</li>
            <li>Lunches-Drinks</li>
            <li>Personal Expenses (laundry, phone calls, all kind of insurances, etc) </li>
          </ul>
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>DOCUMENTS</div>
        <p className="text">

          You will need a valid passport, but you will NOT need a travel visa. Make sure your passport is valid and does not expire at least six months AFTER your scheduled re-entry date to the United States. This date is April 3, 2025.

        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>INSURANCE</div>
        <p className="text">
          [OPTIONAL] If you wish to insure your trip, we recommend you use
          Travel Guard. There are many travel insurance companies out there,
          however, and one of them may prove to be a better option for you. You
          can visit
          <a {...insurance} rel="norferrer" className={styles.link}>
            TravelGuard.com
          </a>
          or call them at <span className={styles.highlight}>800-826-5248</span>
          . They have multiple options for insuring anything from the flight
          itself to the full cost of the trip. Most plans cover sickness,
          injury, or death of a family member.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>
          CANCELLATION AND REFUND POLICY
        </div>
        <p className="text">
          If you need to cancel your tour, you can do it provided it is 90 days before the tour date. After this, the trip is generally non-refundable due to contracts with hotels, guides, and transportation services.

        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>DEPOSIT</div>
        <p className="text">
          A $300 deposit is required to reserve your seat on this trip.

        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>HOW TO SIGN UP FOR THE TRIP</div>
        <p className="text">
          Send an email to Dan Glick at{" "}
          <span className={styles.highlight}>danglick62@gmail.com</span> stating your
          full passport name (exactly as it appears on your passport), your date
          of birth, phone number, email address, and physical address. Please
          state that you want to join the trip and then mail your deposit to
          Dan. Address is provided below.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>PAYMENT POLICY</div>
        <p className="text">
          <span className={styles.highlightItalic}>
            The final payment will be due 90 days before departure.
          </span>{" "}
          Only a check is accepted as a form of payment. Checks should be made
          to: Tribe of Dan Tours and mailed to Dan Glick, 9850 Cedar Cove,
          Alexandria, KY 41001.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>
          AUDIO TOUR GUIDE SOUND SYSTEM
        </div>
        <p className="text">
          Tribe of Dan Tours makes use of an audio tour guide sound receiver.
          With this receiver and the accompanying earbud, you will be able to
          hear your guide wherever you are in the group.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>PRE-TRIP MATERIALS</div>
        <p className="text">
          Tribe of Dan Tours provides the following documents: 1. A list of YouTube videos that one can watch about the country and sites of Turkey. This is very helpful information prior to the trip, and Dan strongly encourages all tourists to take advantage of this and watch them in the months prior to the trip. 2. A suggestive packing list. 3. A prayer guide. 4. A Scripture reading guide. You can download these on this site by clicking the Print PDF button at the top of each page. The file will open in your browser. You can print it out from there or save it to your device by clicking the download icon in your browser. You will find the file in the Downloads folder on your device.

        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>PHYSICAL REQUIREMENTS</div>
        <p className="text">
          There is substantial walking on this trip. It is recommended that you get a good amount of walking exercise in the weeks preceding the trip. There are no special vaccines required for entry into Turkey.

        </p>
      </div>
    </div>
  );
}

export default Generalinfo;
