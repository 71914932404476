import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPictureTurkey}>
      <div className={styles.titleBox}>
        <h1>Visit the country that has more biblical sites than any place other than Israel.
        </h1>
        <p className={styles.title}>Turkey</p>
        <p className={styles.dates}>September 22 - October 3, 2024</p>
        <div className={styles.description}>
          <p>The boat trip to Patmos and touring the “Seven Churches of Revelation” sites will be an experience never to be forgotten.</p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;