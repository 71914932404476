import React from "react";
import styles from "./../Styles/VideoStudy.module.scss";
import { IoCloseOutline } from "react-icons/io5";



function VideoBox({ videoLink, closeModal }) {
    return (
        <section className={styles.modal__bg}>
            <div className={styles.modal__align}>
                <IoCloseOutline
                    className={styles.modal__close}
                    arial-label="Close modal"
                    onClick={closeModal}
                />
                <span className="loader"></span>
                <iframe
                    //className="modal__video-style"
                    loading="lazy"
                    width="100%"
                    height="100%"
                    src={videoLink}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen />

            </div>
        </section>

    );
};

export default VideoBox;