import { NavLink } from "react-router-dom";
import Logo from "../../img/Logo.png";
import styles from "../../Styles/Menu.module.scss";
import { IoClose, IoMenuOutline } from "react-icons/io5";
import { useState } from "react";

function Menu() {
  const [menuClosed, setMenuOpen] = useState(styles.navHidden);
  const [btnHidden, setBtnVisible] = useState(styles.btnHidden);
  const [btnVisible, setBtnHidden] = useState(styles.btnVisible);

  const openMenu = function () {
    setMenuOpen(styles.navOpen);
    setBtnHidden(styles.btnHidden);
    setBtnVisible(styles.btnVisible);
  };

  const closeMenu = function () {
    setMenuOpen(styles.navHidden);
    setBtnHidden(styles.btnVisible);
    setBtnVisible(styles.btnHidden);
  };

  return (
    <div className={styles.menu}>
      <div className={styles.homeBox}>
        <NavLink to="/">
          <img className={styles.logo} src={Logo} alt="logo" />
        </NavLink>
        <NavLink
          to={"/Italy-April-2024"}
          className={styles.home}
        // style={({ isActive }) => {
        //   return {
        //     color: isActive ? "#f4aa00" : " ",
        //     textDecoration: isActive ? "underline" : " ",
        //   };
        // }}
        >
          Italy
        </NavLink>
      </div>
      <nav className={`${styles.nav} ${menuClosed}`}>
        <ul>
          <li>
            <NavLink
              to="Generalinfo"
              onClick={closeMenu}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#f4aa00" : " ",
                  textDecoration: isActive ? "underline" : " ",
                };
              }}
            >
              General info
            </NavLink>
          </li>
          <li>
            <NavLink
              to="Itinerary"
              onClick={closeMenu}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#f4aa00" : " ",
                  textDecoration: isActive ? "underline" : " ",
                };
              }}
            >
              Itinerary
            </NavLink>
          </li>

          <li>
            <NavLink
              to="ScriptureGuide"
              onClick={closeMenu}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#f4aa00" : " ",
                  textDecoration: isActive ? "underline" : " ",
                };
              }}
            >
              Scripture guide
            </NavLink>
          </li>
          <li>
            <NavLink
              to="VideoStudy"
              onClick={closeMenu}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#f4aa00" : " ",
                  textDecoration: isActive ? "underline" : " ",
                };
              }}
            >
              Video Study
            </NavLink>
          </li>
          <li>
            <NavLink
              to="Prayer"
              onClick={closeMenu}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#f4aa00" : " ",
                  textDecoration: isActive ? "underline" : " ",
                };
              }}
            >
              Prayer guide
            </NavLink>
          </li>
          <li>
            <NavLink
              to="PackingList"
              onClick={closeMenu}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#f4aa00" : " ",
                  textDecoration: isActive ? "underline" : " ",
                };
              }}
            >
              Packing list
            </NavLink>
          </li>
          <li>
            <NavLink
              to="TravelAdvice"
              onClick={closeMenu}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#f4aa00" : " ",
                  textDecoration: isActive ? "underline" : " ",
                };
              }}
            >
              Travel advice
            </NavLink>
          </li>
          <li>
            <NavLink
              to="Gallery"
              onClick={closeMenu}
              style={({ isActive }) => {
                return {
                  color: isActive ? "#f4aa00" : " ",
                  textDecoration: isActive ? "underline" : " ",
                };
              }}
            >
              Gallery
            </NavLink>
          </li>
        </ul>
      </nav>
      <button className={`${styles.btn} ${btnHidden}`}>
        <IoClose onClick={closeMenu} />
      </button>
      <button className={`${styles.btn} ${btnVisible}`}>
        <IoMenuOutline onClick={openMenu} />
      </button>
    </div>
  );
}

export default Menu;
