import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPicturePoland}>
      <div className={styles.titleBox}>
        <h1>Go to the land of</h1>
        <p className={styles.title}>Poland</p>
        <p className={styles.dates}>May 6 - 17, 2024</p>
        <div className={styles.description}>
          <p>A vibrant country filled with friendly people, delicious food, and a powerful history. </p>
          <p>Tour Warsaw, Krakow, Zakopane and Auschwitz-Birkenau.</p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;