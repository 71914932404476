import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
// import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
// import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
// import picture_4 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
// import picture_5 from "../../img/Greece/Day 5 Thessalonika.jpeg";
// import picture_6 from "../../img/Greece/Day 6 Kavala.jpeg";
// import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
// import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
// import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
// import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
// import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itinerary from "../../img/Italy/ItalyItinerary.pdf";
import { IoClose } from "react-icons/io5";
import pictureOne from "./../../img/Italy/Itinerary_1.jpeg";
import pictureTwo from "./../../img/Italy/Itinerary_2.jpeg";
import pictureThree from "./../../img/Italy/Itinerary_3.jpeg";

function ItalyItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">April 2 - 11, 2024</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <p className="text">This ten-day trip to Italy will take you to Rome, Naples, Mt. Vesuvius, Pompeii, the famous Amalfi Coast, Sorrento, and the island of Capri. In Rome, we will visit the Roman Forum, the Colosseum, Palatine Hill, the Vatican, St Peter’s Basilica, the Mamertine prison (where it is believed that the Apostles Paul and Peter were imprisoned), the Catacombs, walk on the Via Appia (the ancient Roman road that Paul walked into Rome), and more. After taking the high-speed train to Naples, we will drive and walk to the top of Mt. Vesuvius. Its eruption in AD 79 destroyed the city of Pompeii, whose ruins we will also visit. At Sorrento, we will take a day trip by boat around and to the beautiful mountainous island of Capri, a favorite hangout of Roman emperors. Here, we will take a chair lift to the top of the highest mountain and get an unbelievable view of the island, the Mediterranean Sea, and the mainland of Italy. Charles Dickens once wrote, “There is no spot in the world with such delightful possibilities of repose (and I would add views) as this little isle.” On the day following, we will drive along the gorgeous Amalfi Coast and have a meal in one of its delightful cliffside towns. This is a trip to that will long be remembered and cherished. Don’t miss this one.
      </p>
      <div className="pictureRow">
        <div>
          <img
            className="picture"
            src={pictureOne}
            alt="Poland town"
          />
        </div>
        <div className="hide">
          <img
            className="picture"
            src={pictureTwo}
            alt="Poland solt mines"
          />
        </div>
        <div>
          <img
            className="picture"
            src={pictureThree}
            alt="Polamd mountains"
          />
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Tuesday - April 2</h2>
        <p className="text">
          Depart the USA. Each person/couple will fly from their desired location in the USA to Rome-Fiumicino International Airport.
        </p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Wednesday - April 3</h2>
        <p className="text">
          Upon arrival, a taxi will be taken to the hotel in downtown Rome, where we will stay. That evening, we will walk around downtown Rome, visit Campidoglio, and eat a meal somewhere near our hotel. (staying in Rome)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 2*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div> */}
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Thursday - April 4</h2>
        <p className="text">
          Today, we will tour the Roman Forum, Palatino Hill, the Colosseum, and the Mamertine Prison. As time permits, we will also walk to the Fountain of Trevi, the Spanish Steps, and the Pantheon (staying in Rome).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button> */}
        </p>
        {/*Box 3*/}
        {/* <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Athens: we will stroll through the Ancient Agora (Marketplace)
              visiting the Stoa of Attalos, a stoa lined with shops built in the
              2nd century B.C. which has since been reconstructed for use as the
              Museum of the Ancient Agora. Here we visit many other ancient
              ruins/buildings of ancient Athens. This is where philosophers like
              Socrates gave advice to “know thyself,” and Zeno the Stoic
              lectured on how to control one&apos;s emotions. This is also
              considered to be the seat of western democracy.
            </p>
            
          </div>
        </div> */}
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Friday - April 5</h2>
        <p className="text">
          After a trip to the Vatican, St Peter’s Basilica, and the Sistine Chapel, we will go to the south of Rome and walk a portion of the Via Appia and take in a tour of the Catacombs of San Callisto (staying in Rome).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 4*/}
      {/* <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            On this day, we will drive to base of the highest mountain in
            Greece, Mt. Olympus, making stops at Marathon, and Thermopylae
            (scene of the famous 300 Spartans).
          </p>
          <p className="text">
            Marathon is a small town which was the site of the Battle of
            Marathon (490 BCE), in which the heavily outnumbered Athenian army
            defeated the Persians. It’s believed that Pheidippides, a Greek
            herald at the battle, was sent running from Marathon to Athens to
            announce the victory, which is how the marathon running race was
            conceived in modern times. But there is a lot more to this story and
            you will learn it.
          </p>
          
        </div>
      </div> */}
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Saturday - April 6</h2>
        <p className="text">
          After traveling to Naples on a high-speed train, we will drive to the top of Mt Vesuvius and peer into the world&apos;s most famous volcano. (staying in Pompei)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 5*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Thessalonica"
          />
          <p className="text">
            Travel to Thessalonica: Thessaloniki (pop. 814,000) is called the
            second capital by the Greeks, since it is not only the second
            largest city of Greece after Athens, but also the capital of
            Northern Greece and Macedonia. It is beautifully situated by the
            sea.
          </p>
          <p className="text">
            We will visit the ruins of the ancient city where Paul founded a
            church, stroll along the harbour, take a boat ride in the
            harbor/gulf (Aegean Sea), and view the statute of Alexander the
            Great
          </p>
        </div>
      </div> */}
      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Sunday - April 7</h2>
        <p className="text">
          In Pompei, we will get a guided tour through the ruins of this ancient city that was buried by the eruption of Mt. Vesuvius in AD 79, after which we will take a drive with gorgeous views to Sorrento (staying in Sorrento)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 6*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            Leaving Thessalonika we will travel east to Philippi: After settling
            into our lodgings, we will travel eight miles to the coast and eat
            at the harbour in Kavala (biblical Neapolis), the one Paul sailed
            into from Troas in Turkey/Asia Minor after he received the
            “Macedonian vision” (Acts 16:6-12).
          </p>
        </div>
      </div> */}
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7, Monday - April 8</h2>
        <p className="text">
          On this day, we will catch a boat from Sorrento to the island of Capri. The boat trip will encircle the whole island and dock on the island, giving us several hours in Capri and Anacapri, where we will take the Monte Solar Chair Lift to the highest mountain observation point on the island. The views here are mind-blowing (staying in Sorrento).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 7*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            Tour the archaeological site of ancient Philippi where Paul and
            Silas were imprisoned for casting a spirit of divination out of a
            young slave girl and where they baptized their first European
            convert, Lydia, by the stream beside the town. We will have a Bible
            study from Philippians on the top of the acropolis of Philippi where
            we will enjoy a gorgeous view of the Macedonian mountains and plain
            below the acropolis where the great Roman civil war battle took
            place (42 BC). Here the forces of Octavius (Caesar Augustus) and
            Mark Anthony defeated the legions loyal to Brutus and Cassius, Roman
            senators, who were instigators in the murder of Julius Caesar two
            years earlier. This battle changed the ancient world forever as Rome
            was transformed into empire from a republic.
          </p>
        </div>
      </div> */}
      {/*Day 8*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8, Tuesday - April 9</h2>
        <p className="text">
          Day trip along the famous Amalfi Coast. We plan to take a boat along the coast to Positano, visit and eat in the cliffside town, and then ride a bus back to Sorrento (staying in Sorrento).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 8*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            This will be a travel day to from Philippi to Meteora in the
            mountains of central Greece with a stop in Berea for lunch. It is
            believed that Paul was in the area where we will visit an ancient
            synagogue.
          </p>
          
        </div>
      </div> */}
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Wednesday - April 10</h2>
        <p className="text">
          Travel back to Rome (staying near the airport)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(9);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 9*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[9] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(9);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_9}
            alt="Meteora Monastery"
          />
          <p className="text">
            Meteora: Our visit here will take in at least two of the stunning
            monasteries built on the cliffs of central Greece. There are six
            Meteora monasteries perched on the huge cliffs people can visit
            today. Serene, mystical, extraordinary, breathtaking, immense,
            inspiring, impressive. These are only some of the words people very
            often use in an effort to describe the Meteora monasteries.
          </p>
        </div>
      </div> */}
      {/*Day 10*/}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Thursday - April 11</h2>
        <p className="text">
          Fly back to the USA

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 10*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            This day we will drive to Corinth with a stop and lunch at the
            strait of Actium where Octavian gained his celebrated victory over
            the forces Mark Antony and Cleopatra in 31 BC. This naval battle
            resulted in Octavias (Augustus Caesar) being the sole emperor of the
            Roman Empire. This same man would later give the degree that moved
            Joseph and Mary to Bethlehem where Jesus Christ was born fulfilling
            the prophecy of Micah the prophet. We will also visit the camp of
            Octavian, and where he later set up a memorial to himself for
            winning that historic battle
          </p>
        </div>
      </div> */}

    </div>
  );
}

export default ItalyItinerary;
