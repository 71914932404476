import Menu from "./GreeceMenu";
import Footer from "../../Components/Footer";
import { Outlet } from "react-router-dom";
import styles from "../../Styles/Trip.module.scss";



function GreeceTrip({ trip }) {

  return (
    <div>
      <div className={`${styles.menuPosition} ${styles.container}`}>
        <Menu trip={trip} />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GreeceTrip;
