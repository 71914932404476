import Menu from "./EnglandMenu";
import Footer from "../../Components/Footer";
import { Outlet } from "react-router-dom";
import styles from "../../Styles/Trip.module.scss";


function SwitzTrip() {


  return (
    <div>
      <div className={`${styles.menuPosition} ${styles.container}`}>
        <Menu />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SwitzTrip;