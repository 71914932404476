import packingList from "./../../../src/img/Turkey/Suggested_Packing_List_Turkey.pdf";
import styles from "./../../Styles/PackingList.module.scss";

function packinglist() {
  const adapters = {
    href: "https://www.amazon.com/s?k=turkey+plug+adapter&hvadid=557203313453&hvdev=c&hvlocphy=9014427&hvnetw=g&hvqmt=e&hvrand=10783908465680864129&hvtargid=kwd-29422456157&hydadcr=18889_13353593&tag=googhydr-20&ref=pd_sl_9rcvd3pqsh_e",
    target: "_blank",
  };

  return (

    <div className="pageContainer">
      <p className="title">Suggested Packing List</p>
      <div className="printBox">
        <div className="print">
          <a href={packingList} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      <p className="text">
        My first advice to you is to pack light. Most people make the mistake of overpacking. If you have a question about size and amount of luggage, contact the airlines you are flying with.      </p>

      <p className={`text ${styles.highlightOrange}`}>
        I recommend that you take……

      </p>
      <p className="text">
        A debit card so that you can get Turkish currency at an ATM.
      </p>
      <p className="text">
        You can take a small amount of American cash, but I wouldn’t take too much. Probably best to keep it at $500 or under.

      </p>
      <p className="text">
        I would take one or two credit cards. Most places will accept MasterCard and Visa as payment. Check with your bank to see if they charge a 3 percent international transaction fee. Some do and some do not.
      </p>
      <p className={`text ${styles.highlightBlue}`}>
        LET YOUR BANK(S) KNOW THAT YOU WILL BE IN GREECE AND TO EXPECT CHARGES TO COME THROUGH ON YOUR DEBIT AND CREDIT CARDS. IF YOU FAIL TO DO THIS, THEY MAY SHUT DOWN YOUR CARD BECAUSE THEY SUSPECT FRAUD.
      </p>
      <p className={`text ${styles.highlightOrange}`}>
        Don’t forget your passport. And make sure it is valid (not due to expire for at least six months after you return home). If not, they will not let you board the airplane departing the USA.

      </p>
      <ul className={`text ${styles.list}`}>
        <li>
          Two copies of the main page of your passport (take one copy with you and leave one copy at home with someone). If you lose your passport, having a copy makes it easier to get a provisional one overseas.

        </li>
        <li>If you have medical insurance, take some information or your card with you proving you have it.</li>
        <li>
          The weather should be warm in September. But you can Google the expected weather in the places we will be before you leave home. Consult the itinerary to see where we will be.


        </li>
        <li>Clothes--not too many, about 4-5 changes of clothes. You don’t need any dress clothes.
        </li>
        <li>Laundry soap (very small amount, or you can buy some there and share it)
        </li>
        <li>Most people hand wash clothes in their hotel room. It is usually very expensive to send out your laundry for someone to do it.
        </li>
        <li>A washcloth if you use one. Most overseas hotels do not provide this.</li>
        <li>Adapters to plug in your electronics. Here is the link for Amazon where you can order an adapter that will work in Turkey. <a {...adapters} rel="norferrer" className={styles.link}>Click here</a></li>
        <li>Medicines and supplements that you usually take.
        </li>
        <li>Small Bible or Bible app on your phone.
        </li>
        <li>After arriving, you can buy a Turkish SIM card for your phone if you want one. This will give you internet and GPS wherever you go.
        </li>
        <li>Take good walking/hiking shoes.</li>
        <li>Soft ear plugs to block any night noises that might keep you from sleeping and to block noisy children on the airplane. You can buy a package of these inexpensively at Walmart.. </li>
        <li>Most hotels have hairdryers.
        </li>
        <li>I recommend that you don’t take anything that has a heating element in it. Curling iron, hair dryer, etc.
        </li>
      </ul>

    </div>

  );
}

export default packinglist;

