import travelAdvice from "./../../img/Switzerland/Travel_Advice.pdf";
import pictureOne from "./../../img/Switzerland/Advice_1.jpeg";
import pictureTwo from "./../../img/Switzerland/Advice_2.jpeg";
import pictureThree from "./../../img/Switzerland/Advice_3.jpeg";
import PictureBox from "../../Components/PictureBox";

function Lastadvice() {

  return (
    <div className="pageContainer">
      <p className="title">Travel Advice</p>
      <div className="printBox">
        <div className="print">
          <a href={travelAdvice} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <ol className="margin-left">

        <li className="text">Be flexible. There are always things that happen that your tour leader cannot control. Life can be a bit unpredictable when you are traveling, especially internationally.
        </li>
        <li className="text">Be prepared to deal with time change. Switzerland is 6 hours ahead of eastern standard time here in the USA. It may take a couple days to adjust to the new time zone. Sleeping may be affected.</li>
        <li className="text">It is always good to carry some food and water with you while we travel around the country. You should be able to buy some bottled water and food items (snacks) once you are in the country. Sometimes mealtimes are a bit irregular due to our traveling.
        </li>
      </ol>
      <PictureBox pictureOne={pictureOne} pictureTwo={pictureTwo} pictureThree={pictureThree} />
    </div>
  );
}

export default Lastadvice;

